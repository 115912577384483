import { DqoLoginActions } from "./DqoLoginActions";
import { dqoLoginDispatcher } from "./DqoLoginDispatcher";
import DqoLoginActionTypes from "./DqoLoginActionTypes";

export class DqoLoginActionCreator {
  static loginDqoUser(tgt?: string | null, returnUrl?: string | null, accountName?: string | null): void {
    dqoLoginDispatcher.dispatch(new DqoLoginActions(DqoLoginActionTypes.DQO_LOGIN_REQUESTED, tgt, returnUrl, accountName));
  }

  static redirectingToDqo(): void {
    dqoLoginDispatcher.dispatch(new DqoLoginActions(DqoLoginActionTypes.DQO_LOGIN_REDIRECTING));
  }

  static wrongTenantClearState(): void {
    dqoLoginDispatcher.dispatch(new DqoLoginActions(DqoLoginActionTypes.DQO_LOGIN_WRONG_TENANT));
  }
}
