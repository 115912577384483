import React from 'react';
import Lottie from "lottie-react"
import animationData from "../Animations/registrationlottie.json"
import { Dialog } from '@material-ui/core';

const AnimationPopUp = ({open} : {open : boolean}) => {
	return (
		<Dialog onClose={() => undefined} open={open}>
            <div style={{textAlign: "center", fontSize: "20px", display: "flex"}}>
            Please be patient, we are creating your Data Quality Data Warehouse. It can take 30 or more seconds.
            </div>
            <div style={{overflowY: "hidden"}}>
            <Lottie animationData={animationData} />
            </div>
        </Dialog>
	);
}

export default AnimationPopUp;