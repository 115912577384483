
import React, { useEffect } from 'react';

function useCombinedRef<T>(...refs: any[]): React.RefObject<T> {
	const targetRef = React.createRef<T>();

	useEffect(() => {
		refs.forEach(ref => {
			if(!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
}

export default useCombinedRef;