import React, { ChangeEvent, Component } from "react";

import readingTime from "reading-time";

import { MuiThemeProvider, Theme } from "@material-ui/core/styles";
import { MainPage } from "pages";

// ----------
import { CssBaseline } from "@material-ui/core";

import { UserApiClient } from "services/apiClient";
import { auth } from "firebaseSingleton";
import authentication from "services/authentication";
import appearance from "services/appearance";
import { Unsubscribe } from "firebase";
import { apiKeyRequestStore } from "../../state/apikeyrequest/ApiKeyRequestStore";
import { dqoLoginStore } from "../../state/dqologin/DqoLoginStore";

import ErrorBoundary from "components/ErrorBoundary";
import LaunchScreen from "components/LaunchScreen";
import Authorization from "components/Authorization";
import Toast from "components/Toast";
import { AxiosResponse } from "axios";
import { UserModel } from "../../api";
import { ApiKeyRequestState } from "../../state/apikeyrequest/ApiKeyRequestState";
import AppProviders from "../../contexts";
import { DqoLoginState } from "../../state/dqologin/DqoLoginState";
import { DqoLoginActionCreator } from "../../state/dqologin/DqoLoginActionCreator";
import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";

const initialState = {
  ready: false,
  performingAction: false,
  theme: appearance.defaultTheme,
  user: null,
  userData: null,
  roles: [],
  templateData: null,

  aboutDialog: {
    open: false,
  },

  signUpDialog: {
    open: false,
  },

  signInDialog: {
    open: false,
  },

  settingsDialog: {
    open: false,
  },

  deleteAccountDialog: {
    open: false,
  },

  signOutDialog: {
    open: false,
  },

  snackbar: {
    autoHideDuration: 0,
    message: "",
    open: false,
  },
};

class App extends Component<any> {
  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  changeUser = (user: any) => {
    this.setState({
      user: auth.currentUser,
    });
    let apiKeyRequestState: ApiKeyRequestState = apiKeyRequestStore.getState();
    if (
      apiKeyRequestState !== undefined &&
      apiKeyRequestState != null &&
      apiKeyRequestState.apiKeyRequest != null
    ) {
      window.history.pushState(
        null,
        "API Key request",
        "/requestapikey/" + apiKeyRequestState.apiKeyRequest
      );
    }

    let dqoLoginState: DqoLoginState = dqoLoginStore.getState();
    if (
      dqoLoginState !== undefined &&
      dqoLoginState != null &&
      dqoLoginState.tgt &&
      dqoLoginState.returnUrl
    ) {
      // requesting return login url

      let tgt = dqoLoginState.tgt;
      let returnUrl = dqoLoginState.returnUrl;

      firebase.auth().currentUser?.getIdToken()
         .then((token: string) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            
            UserApiClient.redirectAuthenticatedUser(tgt, returnUrl)
            .then((response): void => {
              DqoLoginActionCreator.redirectingToDqo();
              window.location.replace(response.data);
            })
            .catch((response) => {
              window.history.pushState(
                null,
                "Invalid DQO Cloud account",
                "/wrongtenant"
              );
            });
         });


    }
  };

  onAuthStateChangedObserver?: Unsubscribe;
  userDocumentSnapshotListener?: () => void;

  resetState = (callback?: () => void) => {
    this.setState(
      {
        ready: true,
        theme: appearance.defaultTheme,
        user: null,
        userData: null,
        roles: [],
      },
      callback
    );
  };
  /** AUTHORIZATION FUNCTION (CHANGE ON FUNCTIONS AND STATES*/
  signInWithAuthProvider = (providerId: string) => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signInWithAuthProvider(providerId)
          .then((user) => {
            (this.props as any).dialogProps.onClose(() => {
              const displayName = user.displayName;
              const emailAddress = user.email;
              (this.props as any).openSnackbar(
                `Signed in as ${displayName || emailAddress}`
              );
            });

            this.setState({
              performingAction: false,
              user: auth.currentUser,
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              case "auth/account-exists-with-different-credential":
              case "auth/auth-domain-config-required":
              case "auth/cancelled-popup-request":
              case "auth/operation-not-allowed":
              case "auth/operation-not-supported-in-this-environment":
              case "auth/popup-blocked":
              case "auth/popup-closed-by-user":
              case "auth/unauthorized-domain":
                (this.props as any).openSnackbar(message);
                return;

              default:
                (this.props as any).openSnackbar(message);
                return;
            }
          })
          .finally(() => {});
      }
    );
  };
  handleEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };
  handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  setTheme = (theme: Theme, callback: () => void) => {
    if (!theme) {
      this.setState(
        {
          theme: appearance.defaultTheme,
        },
        callback
      );

      return;
    }

    this.setState(
      {
        theme: appearance.createTheme ? appearance.createTheme(theme) : null,
      },
      callback
    );
  };
  openDialog = (dialogId: string, callback?: () => void) => {
    const dialog = (this.state as any)[dialogId];
    if (!dialog || dialog.open === undefined || dialog.open === null) {
      return;
    }

    dialog.open = true;

    this.setState({ [dialogId]: dialog }, callback);
  };

  closeDialog = (dialogId: string, callback?: () => void) => {
    const dialog = (this.state as any)[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback: () => void) => {
    this.setState(
      {
        aboutDialog: {
          open: false,
        },

        signUpDialog: {
          open: false,
        },

        signInDialog: {
          open: false,
        },

        settingsDialog: {
          open: false,
        },

        deleteAccountDialog: {
          open: false,
        },

        signOutDialog: {
          open: false,
        },
      },
      callback
    );
  };

  deleteAccount = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .deleteAccount()
          .then(() => {
            this.closeAllDialogs(() => {
              Toast.add({
                text: "Deleted account",
                color: "#fd3744",
                autohide: true,
                delay: 5000,
              });
            });
          })
          .catch((reason) => {
            //       const code = reason.code;
            const message = reason.message;

            Toast.add({
              text: message,
              color: "#fd3744",
              autohide: true,
              delay: 5000,
            });
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  signOut = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signOut()
          .then(() => {
            this.closeAllDialogs(() => {
              // const params = new URLSearchParams;
              // params.delete()
              //this.openSnackbar("Signed out");
              Toast.add({
                text: "Signed out",
                color: "#777777",
                autohide: true,
                delay: 5000,
              });
            });
          })
          .catch((reason) => {
            //       const code = reason.code;
            const message = reason.message;

            Toast.add({
              text: message,
              color: "#fd3744",
              autohide: true,
              delay: 5000,
            });
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  render() {
    const {
      ready,
      performingAction,
      theme,
      user,
      userData,
      roles,
      aboutDialog,
      signUpDialog,
      signInDialog,
      settingsDialog,
      deleteAccountDialog,
      signOutDialog,
      snackbar,
    } = this.state as any;

    return (
      <div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <AppProviders>
          <MuiThemeProvider theme={theme}>
            <style>{"body { background-color: white; }"}</style>
            <CssBaseline />
            <ErrorBoundary>
              {!ready && <LaunchScreen />}
              {ready &&
                (user ? (
                  <MainPage
                    user={user}
                    roles={roles}
                    performingAction={performingAction}
                    theme={theme as Theme}
                    userData={userData}
                    openDialog={this.openDialog}
                    deleteAccount={this.deleteAccount}
                    closeDialog={this.closeDialog}
                    signOut={this.signOut}
                    aboutDialog={aboutDialog}
                    signUpDialog={signUpDialog}
                    signInDialog={signInDialog}
                    settingsDialog={settingsDialog}
                    deleteAccountDialog={deleteAccountDialog}
                    signOutDialog={signOutDialog}
                  />
                ) : (
                  <Authorization changeUser={this.changeUser} />
                ))}
            </ErrorBoundary>
          </MuiThemeProvider>
        </AppProviders>
      </div>
    );
  }

  componentDidMount() {
    this.onAuthStateChangedObserver = auth.onAuthStateChanged(
      (user) => {
        // The user is not signed in or doesn’t have a user ID.
        if (!user || !user.uid) {
          if (this.userDocumentSnapshotListener) {
            this.userDocumentSnapshotListener();
          }

          this.resetState();

          return;
        }

        user.getIdToken().then((token: string) => {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          UserApiClient.getCurrentUser()
            .then((data: AxiosResponse<UserModel>) => {
              if (!data) {
                this.resetState();
                return;
              }

              authentication
                .getRoles()
                .then((value) => {
                  this.setState({
                    ready: true,
                    user: user,
                    userData: data,
                    roles: value || [],
                  });
                })
                .catch((reason) => {
                  this.resetState(() => {
                    //       const code = reason.code;
                    const message =
                      typeof reason === "string" ? reason : reason?.message;
                    if (message === undefined || message === "") return;

                    Toast.add({
                      text: message,
                      color: "#fd3744",
                      autohide: true,
                      delay: 5000,
                    });
                  });
                });
            })
            .catch((reason: any) => {       
              auth.signOut();    
              this.resetState(() => {
                Toast.add({
                  text: "You are not allowed to access DQO, please sign up for a free account or request access from your administrator",
                  color: "#fd3744",
                  autohide: true,
                  delay: 5000,
                });
              }); 
            });
          });
      },
      (error) => {
        this.resetState(() => {
          //const code = error.code;
          const message = error.message;

          Toast.add({
            text: message,
            color: "#fd3744",
            autohide: true,
            delay: 5000,
          });
        });
      }
    );
  }
  componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }

    if (this.userDocumentSnapshotListener) {
      this.userDocumentSnapshotListener();
    }
  }
}

export default App;
