import React from 'react';
import useStyles from 'styles';
import RedirectButton from '../RedirectButton';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SignUpWindow from './SignUpWindow';
import Logo from 'components/Logo';

//TODO validation

type SignUp = {
  changeUser: (arg0: any) => void;
};

const SignUp = ({ changeUser }: SignUp) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="sm">
      <Logo background />
      <div className={classes.signRoute}>
        <RedirectButton text="Sign In" link="/" />
      </div>
      <Grid container component="main" className={classes.root}>
        <SignUpWindow changeUser={changeUser} />
      </Grid>
    </Container>
  );
};

export default SignUp;
