
import React from 'react';
import { EmptyState } from 'components';
import { Link } from 'react-router-dom';
import { Fab, Box, Button } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';

const ErrorPage = () => {
	return (
		<EmptyState
			title="Cannot reach server"
			description="An error occured trying to load the current page"
			button={
				<Fab variant="extended" color="primary" component='button' onClick={() => window.location.reload()}>
					<Box clone mr={1}>
						<RefreshIcon />
					</Box>
					Refresh
				</Fab>
			}
		/>
	);
}

export default ErrorPage;