
import { List } from '@material-ui/core';
import AccountTab from 'components/AccountTab';
import Header from 'components/Header';
import SecurityTab from 'components/SecurityTab';
import React from 'react';
import useStyles from "../../styles";

type Account = {
  user: any;
  userData: any;
  onDeleteAccountClick: () => void;
};

const Account = ({
  user, userData, onDeleteAccountClick
}: Account) => {
  const classes = useStyles();
  return (
    <div className={classes.pageWrapper}>
      <Header title="Account" />
  
      <List>
          <AccountTab
            user={user}
            userData={userData}
            onDeleteAccountClick={onDeleteAccountClick}
          />
          <SecurityTab
            user={user}
            userData={userData}
          />
      </List>
    </div>
  )
}

export default Account;