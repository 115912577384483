import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MenuItemSettings } from 'types/interfaces';
import useStyles, { colors } from 'styles';
import { AccountCircle as AccountCircleIcon, Menu as MenuIcon, Close as CloseIcon, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Logo } from 'components';
import { VscFolderLibrary, VscSettingsGear, VscSignOut } from 'react-icons/vsc';

type Bar = {
  user: any;
  roles: any;
  performingAction: boolean;
  userData: any;
  openMenu: (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => void;
  closeMenu: () => void;
  onSignUpClick: () => void;
  onSignInClick: () => void;
  onSettingsClick: () => void;
  onSignOutClick: () => void;
  onManageAssetLibrary: () => void;
  menu: { anchorEl?: EventTarget & HTMLElement };
  open: boolean;
  setOpen: (arg0: boolean) => void;
};

const Bar = ({
  user,
  performingAction,
  openMenu,
  closeMenu,
  onSignUpClick,
  onSignInClick,
  onSettingsClick,
  onSignOutClick,
  onManageAssetLibrary,
  menu,
  open,
  setOpen
}: Bar) => {
  const menuItems: MenuItemSettings[] = [
    {
      name: "Settings",
      onClick: onSettingsClick,
      icon: <VscSettingsGear/>
    },
    {
      name: "Sign out",
      divide: true,
      onClick: onSignOutClick,
      icon: <VscSignOut/>
    },
  ];

  const classes = useStyles();
  const screen = useMediaQuery('@media screen and (min-width:600px)');

  return (
    <Toolbar className={classes.mainBar} style={{zIndex: 10}} disableGutters>
      {screen &&
      <IconButton
        color='inherit'
        onClick={() => setOpen(!open)}
        style={{color: colors.dark, margin: '9px'}}
        size='small'
      >
        {open
        ? <ChevronLeft />
        : <ChevronRight />}
      </IconButton>}

      <Logo color />

      {user && (
        <>
          <IconButton
            color='inherit'
            disabled={performingAction}
            onClick={openMenu}
            style={{marginLeft: 'auto', marginRight: '0.5rem', color: 'gray'}}
            size='small'
          >
            <AccountCircleIcon />
          </IconButton>

          <Menu
            anchorEl={menu.anchorEl}
            open={Boolean(menu.anchorEl)}
            onClose={closeMenu}
          >
            {menuItems.map((menuItem: MenuItemSettings, index: number) => {
              if (menuItem.hasOwnProperty("condition") && !menuItem.condition) {
                return null;
              }

              let component;

              if (menuItem.to) {
                component = (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={menuItem.to}
                    onClick={closeMenu}
                  >
                    {menuItem.icon}&nbsp;&nbsp;{menuItem.name}
                  </MenuItem>
                );
              } else {
                component = (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      closeMenu();

                      menuItem.onClick();
                    }}
                  >
                    {menuItem.icon}&nbsp;&nbsp;{menuItem.name}
                  </MenuItem>
                );
              }

              if (menuItem.divide) {
                return (
                  <span key={index}>
                    <Divider />

                    {component}
                  </span>
                );
              }

              return component;
            })}
          </Menu>
        </>
      )}

      {!user && (
        <ButtonGroup
          color="inherit"
          disabled={performingAction}
          variant="outlined"
        >
          <Button onClick={onSignUpClick}>Sign up</Button>
          <Button onClick={onSignInClick}>Sign in</Button>
        </ButtonGroup>
      )}
    </Toolbar>
  );
};

export default Bar;
