
import React, { MouseEvent, useState } from "react";
import { Preloader } from "../";
import Header from 'components/Header';
import useStyles from "styles";

const Actions: any = (props:
  { children: JSX.Element | JSX.Element[], style?: React.CSSProperties }) => {
		return props.children;
};

type ItemPopup = {
	title: string;
	subtitle?: string;
	isLoading?: boolean;

	image: string;
	alt?: string;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;

  closePopup: () => void;

	children?: JSX.Element | JSX.Element[];
  modalRef?: React.RefObject<HTMLDivElement>;
	imgRef?: React.RefObject<HTMLImageElement>;
	popRef?: React.RefObject<HTMLDivElement>;
};

const ItemPopup = ({
	title,
	subtitle,
	isLoading,
	image,
	alt,
  style,
  imgStyle,
	closePopup,
	children,
	modalRef,
  imgRef,
  popRef,
}: ItemPopup) => {
  const classes = useStyles();

	const arrayChildren = Array.isArray(children) ? children : [children];
	const items = arrayChildren.filter(child => child && child.type !== Actions);
  const actions = arrayChildren.find(child => child && child.type === Actions);

  const closePopupByClickInOtherArea = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
			e.preventDefault();
			e.stopPropagation();

			closePopup();
    }
  };

  return (
    <div
      onClick={(e) => closePopupByClickInOtherArea(e)}
      ref={modalRef}
      className={classes.modal}
    >
      <div className={classes.modalGalleryTemplateWrapper}>
        <div className={classes.imageWrapperPopup} style={style}>
          <div ref={popRef} className={classes.modalGalleryTemplate}>
            {isLoading
            ? <div>
                <Preloader />
                <div className={classes.popupBlockTemplate} />
              </div>
            : <></>
            }
            <Header title={title} style={{marginBottom: '10px', fontSize: '0.75rem'}}>
              <span
                onClick={() => closePopup()}
                className={classes.closePopupIcon}
              >
                &times;
              </span>
            </Header>
            <div className={classes.modalGalleryTemplateTags}>
							{subtitle ??
								<div className={classes.modalGalleryTemplateSize}>
									{subtitle}
								</div>
							}
            </div>
            <img src={image} className={classes.modalGalleryTemplateThumbnail} style={imgStyle} alt={alt} ref={imgRef} />
						{items}
            <div className={classes.modalGalleryTemplateRowActions}>
							{actions}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ItemPopup.Actions = Actions;
export default ItemPopup;