import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

type Authorization = {
  changeUser: (arg0: any) => void;
};

const Authorization = ({ changeUser }: Authorization) => {
  return (
    <>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <Route exact path="/registration">
            <SignUp changeUser={changeUser} />
          </Route>
          <Route exact path="/passwordreset" >
            <ResetPassword/>
          </Route>
          <Route exact path="/requestapikey/:apiKeyRequest">
            <SignIn changeUser={changeUser} />
          </Route>
          <Route exact path="/login">
            <SignIn changeUser={changeUser} />
          </Route>
          <Route path="/">
            <SignIn changeUser={changeUser} />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Authorization;
export { default as SignInMedia } from './SignInMedia';
export { default as RedirectButton } from './RedirectButton';
