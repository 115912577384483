import React, {
  Component,
} from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { Theme, withStyles } from '@material-ui/core/styles';

import {
  Button
} from '@material-ui/core';

import {
  AccessTime as AccessTimeIcon,
  DeleteForever as DeleteForeverIcon,
  Email as EmailIcon
} from '@material-ui/icons';

import Toast from 'components/Toast';
import { auth } from 'firebaseSingleton';
import Field from 'pages/Account/Field';
import authentication from 'services/authentication';

const styles = (theme: Theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  emailAddress: "",
  performingAction: false,
  sentVerificationEmail: false,
  errors: null,
};

class AccountTab extends Component {
  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  static propTypes: any;

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .verifyEmailAddress()
          .then(() => {
            this.setState(
              {
                sentVerificationEmail: true,
              },
              () => {
                Toast.add({
                  text: 'Sent verification e-mail',
                  color: "#00FF00",
                  autohide: true,
                  delay: 5000,
                });
              }
            );
          })
          .catch((reason) => {
            //       const code = reason.code;
            const message = reason.message;

            Toast.add({
              text: message,
              color: "#fd3744",
              autohide: true,
              delay: 5000,
            });
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  render() {
    // Styling
    const { classes } = this.props as any;

    // Properties
    const { user } = this.props as any;

    // Events
    const { onDeleteAccountClick } = this.props as any;

    const {
      showingField,
      performingAction,
      sentVerificationEmail,
    } = this.state as any;

    return (
      <>
        <Field icon={<EmailIcon />} style={{minHeight: '95px'}}>
          <Field.Main
            primary='E-mail address'
            secondary={user.email ? user.email : 'You don\'t have an email address'}
          />

          {/* <Field.Info
            title={user.email
              ? user.emailVerified ? 'Verified' : 'Not verified'
              : 'No email address'}
          >
            {user.email && user.emailVerified
            ? <CheckIcon color='primary' />
            : <WarningIcon color='error' />}
          </Field.Info> */}

          {/* {user.email && !user.emailVerified
          ? <Field.Action title="Verify">
              <IconButton
                color='secondary'
                disabled={performingAction || sentVerificationEmail}
                onClick={this.verifyEmailAddress}
              >
                <CheckIcon />
              </IconButton>
            </Field.Action>
          : <></>} */}
        </Field>

        <Field icon={<AccessTimeIcon />} style={{minHeight: '95px'}}>
          <Field.Main
            primary='Signed in'
            secondary={moment(user.metadata.lastSignInTime).format('LLLL')}
          />
        </Field>

        <Field icon={<DeleteForeverIcon />} style={{minHeight: '95px'}}>
          <Field.Main
            primary='Delete account'
            secondary={'Accounts can\'t be recovered'}
          />

          <Field.Action title=''>
            {(auth && auth.tenantId && auth.tenantId.length > 0) ? 
            <></>
            : 
            <Button
              disabled={performingAction}
              variant="contained"
              onClick={onDeleteAccountClick}
              style={{backgroundColor: "#E3170A"}}
            >
              Delete
            </Button>
            }
          </Field.Action>
        </Field>
      </>
    );
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
