import React, { ChangeEvent, useState } from 'react';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import useStyles from 'styles';
import validate from 'validate.js';
import constraints from 'constraints';
import authentication from 'services/authentication';
import { Loader, SignInMedia } from 'components';
import { auth } from 'firebaseSingleton';
import { Link } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import Toast from 'components/Toast';
import AnimationPopUp from 'components/AnimationPopUp';

type SignUpWindow = {
  changeUser: (arg0: any) => void;
};

const SignUpWindow = ({ changeUser }: SignUpWindow) => {
  const classes = useStyles();

  //HOOKS
  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [signedUpWithProvider, setSignedUpWithProvider] = useState(false);
  const [errors, setErrors] = useState<{emailAddress: string[], password: string[], passwordConfirmation: string[], terms: string[]} | null>(null);
  const [open, setOpen] = useState(false)
  const signUp = () => {
    const hadErrors =
        signedUpWithProvider ?
            validate(
                {
                  terms: checked,
                },
                {
                  terms: constraints.terms
                }
            )
        :        
        validate(
        {
          emailAddress: emailAddress?.trim() ?? '',
          password: password,
          passwordConfirmation: passwordConfirmation,
          terms: checked,
        },
        {
          emailAddress: constraints.emailAddress,
          password: constraints.password,
          passwordConfirmation: constraints.passwordConfirmation,
          terms: constraints.terms
        }
      );

    setErrors(hadErrors);

    if (!hadErrors && checked) {
      setErrors(null);

      auth.tenantId = null;
      if (signedUpWithProvider) {
        changeUser(auth.currentUser);
      } else {
        setPerformingAction(true);
        setOpen(true)
        authentication
            .signUpWithEmailAddressAndPassword(emailAddress, password)
            .then((user) => {
              setPerformingAction(false);
              changeUser(user);
            })
            .catch((reason) => {
              if (reason !== undefined) {
                const message = reason instanceof String ? reason : reason.message;

                Toast.add({
                  text: message,
                  color: "#fd3744",
                  autohide: true,
                  delay: 5000,
                });
              }
            })
            .finally(() => {
              setPerformingAction(false);
              setOpen(false);
            });
      }
    }
  };
  const handleEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const emailAddress = event.target.value;
    setEmailAddress(emailAddress);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPassword(password);
  };
  const handlePasswordConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const passwordConfirmation = event.target.value;
    setPasswordConfirmation(passwordConfirmation);
  };
  const signUpWithAuthProvider = (providerId: string) => {
    setPerformingAction(true);
    setOpen(true)
    authentication
        .signUpWithAuthProvider(providerId)
        .then(() => {
            setPerformingAction(false);
            setSignedUpWithProvider(true);
        })
        .catch((reason) => {
          setPerformingAction(false);
          if (reason !== undefined) {
            const message = reason instanceof String ? reason : reason.message;

            Toast.add({
              text: message,
              color: "#fd3744",
              autohide: true,
              delay: 5000,
            });
          }
        })
        .finally(() => {
          setOpen(false)
        });
  };
  return (
    <div className={classes.signWrapper}>
      <div onClick={() => setOpen(true)}>SHOW DIALOG</div>
      <Paper elevation={3}>
        <div className={classes.signGradient}></div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h6">
            Let's create your account.
          </Typography>
          <Typography component="h1" variant="h6" display="block">
            Sign up for free. No credit card required.
          </Typography>
          <SignInMedia
            signInWithAuthProvider={signUpWithAuthProvider}
            handleEmailAddressChange={handleEmailAddressChange}
            handlePasswordChange={handlePasswordChange}
          />
          <span className={classes.spanText}>OR</span>
          <div className={classes.form}>
            <TextField
              variant="outlined"
              autoComplete="email"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              disabled={performingAction || signedUpWithProvider}
              error={errors?.emailAddress?.length! > 0}
              value={emailAddress}
              helperText={errors?.emailAddress?.length! > 0 ? errors?.emailAddress.join(', ') : ''}
              onChange={handleEmailAddressChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              disabled={performingAction || signedUpWithProvider}
              error={errors?.password?.length! > 0}
              helperText={errors?.password?.length! > 0 ? errors?.password?.join(', ') : ''}
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              onChange={handlePasswordChange}
              value={password}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm password"
              type="password"
              autoComplete="password"
              disabled={performingAction || signedUpWithProvider}
              error={errors?.passwordConfirmation?.length! > 0}
              helperText={errors?.passwordConfirmation?.length! > 0 ? errors?.passwordConfirmation?.join(', ') : ''}
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
            />

            <Checkbox color="primary" name="terms" checked={checked} onChange={(event) => setChecked(event.target.checked)} />
            <span className={classes.spanText} style={{color: errors?.terms?.length! > 0 ? '#e3170a' : undefined}}>
               I have read and I accept the{" "}
              <u>
                <a
                  className={classes.spanText}
                  style={{color: errors?.terms?.length! > 0 ? '#e3170a' : undefined}}
                  href="https://dqops.com/terms-and-conditions/"
                >
                  Terms and Conditions
                </a>
              </u>{", "}
              <u>
                <a
                  className={classes.spanText}
                  style={{color: errors?.terms?.length! > 0 ? '#e3170a' : undefined}}
                  href="https://dqops.com/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </u>{" and  "}
              <u>
                <a
                  className={classes.spanText}
                  style={{color: errors?.terms?.length! > 0 ? '#e3170a' : undefined}}
                  href="https://dqops.com/cookies-policy/"
                >
                  Cookies Policy
                </a>
              </u>
              .
            </span>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !emailAddress ||
                !password ||
                !passwordConfirmation ||
                performingAction
              }
              onClick={signUp}
            >
              {performingAction ? <Loader /> : 'Sign Up'}
            </Button>
            {/*---------------------------------*/}
            <div className={classes.spanWrapper}>
              <span className={classes.spanText}>
                <Link className={classes.signLinkAuth} to="/">
                  Have an account? Sign In
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Paper>
      <div className={classes.terms}>
        <span>
          {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/terms-and-conditions/">
            Terms and Conditions
          </a>
        </span>
        <span>
          &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/privacy-policy/">
            Privacy Policy
          </a>
        </span>
        <span>
          &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/cookies-policy/">
            Cookies Policy
          </a>
        </span>
      </div>
      <AnimationPopUp open= {open}/>
    </div>
  );
};

export default SignUpWindow;
