import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import useStyles, { colors } from "styles";
import MenuIcon from "../Icons/MenuIcon";
import classnames from "classnames";
import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from "@material-ui/core";

type NavBar = {
  open: boolean;
};

const NavBar = ({ open }: NavBar) => {
  const [value, setValue] = React.useState(0);
  const [drawerWidth, setDrawerWidth] = useState(240);
  const [textActive, setTextActive] = useState(open);
  const screen = useMediaQuery("@media screen and (min-width:600px)");

  const classes = useStyles();
  const history = useHistory();

  const navBarLinks = [
    {
      title: "Home",
      path: "/home",
      icon: <MenuIcon type="home" />,
    },
    {
      title: "Account",
      path: "/account",
      icon: <MenuIcon type="user" />,
    },
  ];

  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    setDrawerWidth(open ? 220 : 48);
  }, [open]);

  return (
    <>
      {screen ? (
        <CSSTransition
          in={open}
          timeout={500}
          classNames={{
            enter: classes.navbarEnter,
            enterActive: classes.navbarEnterActive,
            exit: classes.navbarExit,
            exitActive: classes.navbarExitActive,
          }}
          onEntering={() => setTextActive(true)}
          onExiting={() => setTextActive(false)}
        >
          <Drawer
            variant="permanent"
            open={open}
            className={classnames(classes.navbar, {
              [classes.navbarHidden]: !open,
            })}
            style={{ width: drawerWidth, zIndex: 5, position: "absolute" }}
            PaperProps={{ elevation: 4 }}
          >
            <List
              className={classes.navbarNav}
              style={{
                backgroundColor: colors.navbar.background,
                width: drawerWidth,
                transition: "width 500ms ease",
                overflowX: "hidden",
              }}
            >
              {navBarLinks.map((link) => (
                <ListItem
                  button
                  key={link.path}
                  className={classnames(classes.navItem, {
                    [classes.navLinkCurrent]: isActive(link.path),
                  })}
                  style={{ maxHeight: "64px" }}
                  onClick={() => history.push(link.path)}
                >
                  <Tooltip title={open ? "" : link.title}>
                    <ListItemIcon
                      style={{ paddingRight: open ? "8px" : undefined }}
                    >
                      {link.icon}
                    </ListItemIcon>
                  </Tooltip>
                  {textActive && <ListItemText primary={link.title} />}
                </ListItem>
              ))}
            </List>
          </Drawer>
        </CSSTransition>
      ) : (
        <Paper elevation={3}>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            className={classes.navbar}
          >
            {navBarLinks.map((link) => (
              <BottomNavigationAction
                icon={link.icon}
                component={Link}
                to={link.path}
                style={{ paddingTop: "6px", minWidth: "unset" }}
              />
            ))}
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
};

export default NavBar;
