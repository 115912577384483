
import { auth } from 'firebaseSingleton';
import { useEffect, useState } from 'react';

export function getLocalStorage(key: string, user?: string) {
  const userKey = user ?? auth.currentUser?.uid ?? 'NOT_LOGGED_IN';

  const item = window.localStorage.getItem(userKey);
  const json = JSON.parse(item ?? '{}');
  return json[key];
}

function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [userKey, setUserKey] = useState<string>(auth.currentUser?.uid ?? 'NOT_LOGGED_IN');

  useEffect(() => {
    setUserKey(auth.currentUser?.uid ?? 'NOT_LOGGED_IN');
  }, [auth.currentUser]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      return getLocalStorage(key, userKey) ?? initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T): void => {
    try {
      // Save state
      setStoredValue(value);
      // Save to local storage
      const saved = window.localStorage.getItem(userKey);

      var item;
      if(saved) {
        item = JSON.parse(saved);
      } else {
        item = {};
      }

      // if the item is an array, push to it
      item[key] = value;

      window.localStorage.setItem(userKey, JSON.stringify(item));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
