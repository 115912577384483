import {
  UsersApiFactory,
  TenantApiFactory,
  ApiKeyRequestApiFactory,
  DSCCApiFactory
} from "../api";
import { Configuration } from "../api/configuration";
import axios from "axios";

export const UserApiClient = UsersApiFactory(
    {} as Configuration,
    "",
    axios
);
export const TenantApiClient = TenantApiFactory(
    {} as Configuration,
    "",
    axios
);

export const ApiKeyRequestClient = ApiKeyRequestApiFactory(
    {} as Configuration,
    "",
    axios
);

export const DSCCRequestClient = DSCCApiFactory(
    {} as Configuration,
    "",
    axios
);
