import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface IDeleteConfirmDialogProps {
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2.5, 2.5, 1),
  },
  content: {
    padding: theme.spacing(0, 2.5, 2.5),
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    flexGrow: "initial",
    minWidth: theme.spacing(10),
    "&:first-child": {
      marginRight: theme.spacing(1.5),
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

const DeleteConfirmDialog: React.FC<IDeleteConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle className={classes.title}>Remove Dashboard</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.description}>
          Are you sure to remove this dashboard?
        </Typography>
        <div className={classes.footer}>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={handleConfirm}
          >
            Yes
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            No
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
