import React from 'react';
import useStyles from 'styles';
import { Container, Grid } from '@material-ui/core';
import RedirectButton from '../RedirectButton';
import ResetPasswordWindow from './ResetPasswordWindow';
import Logo from 'components/Logo';

type ResetPassword = {
  resetPass?: (arg0: any) => void;
};

const ResetPassword = ({ resetPass }: ResetPassword) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="sm">
      <Logo background />
      <div className={classes.signRoute}>
        <RedirectButton text="Sign In" link="/" />
      </div>
      <Grid container component="main" className={classes.root}>
        <ResetPasswordWindow />
      </Grid>
    </Container>
  );
};
export default ResetPassword;