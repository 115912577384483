import React, { useState } from 'react';
import useStyles from 'styles';
import AuthProviderList from 'components/AuthProviderList';
import validate from 'validate.js';
import constraints from 'constraints';
import { ISignInMedia } from 'types/interfaces';

const SignInMedia: React.FC<ISignInMedia> = ({
  signInWithAuthProvider,
  handleEmailAddressChange,
  handlePasswordChange,
}) => {
  const classes = useStyles();
  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAdress] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState(
    validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    )
  );

  return (
    <div className={classes.singInMediaWrapper}>
      <AuthProviderList
        performingAction={performingAction}
        onAuthProviderClick={signInWithAuthProvider}
      />
    </div>
  );
};

export default SignInMedia;
