import React, { Component } from 'react';

import EmptyState from 'components/EmptyState';

class AdminPage extends Component {
  render() {
    return <EmptyState title='Admin' />;
  }
}

export default AdminPage;
