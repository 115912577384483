import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, ButtonGroup, Button } from '@material-ui/core';
import authProviders from 'authProviders';

class AuthProviderList extends Component {
  static defaultProps: any = {
    gutterBottom: false,
    performingAction: false,
  };

  static propTypes: any = {
    // Properties
    gutterBottom: PropTypes.bool,
    performingAction: PropTypes.bool,

    // Events
    onAuthProviderClick: PropTypes.func.isRequired,
  };

  render() {
    // Properties
    const { gutterBottom, performingAction } = this.props as any;

    // Events
    const { onAuthProviderClick } = this.props as any;

    return (
      <Box mb={gutterBottom ? 3 : 0}>
        <ButtonGroup
          disabled={performingAction}
          fullWidth
          orientation="vertical"
          variant="outlined"
        >
          {authProviders.map((authProvider) => {
            const AuthProviderButton = withStyles({
              root: {
                color: authProvider.color,
                height: "51px",
                textTransform: "capitalize",
              },
            })(Button);

            return (
              <AuthProviderButton
                key={authProvider.providerId}
                startIcon={authProvider.icon}
                onClick={() => onAuthProviderClick(authProvider.providerId)}
              >
                {authProvider.name}
              </AuthProviderButton>
            );
          })}
        </ButtonGroup>
      </Box>
    );
  }
}

export default AuthProviderList;
