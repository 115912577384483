import React, { ChangeEvent, SyntheticEvent, useState, useEffect } from 'react';
import useStyles from 'styles';
import { Paper, Typography, TextField, Button } from '@material-ui/core';

import { Loader, SignInMedia } from 'components';
import { Link } from "react-router-dom";
import validate from 'validate.js';
import constraints from 'constraints';
import authentication from 'services/authentication';
import { auth } from 'firebaseSingleton';
import Toast from 'components/Toast';
import { UserApiClient } from "../../../../services/apiClient";
import { dqoLoginStore } from "../../../../state/dqologin/DqoLoginStore";
import { DqoLoginState } from "../../../../state/dqologin/DqoLoginState";


type SignInWindow = {
  changeUser: (arg0: any) => void;
  tenantAccountName: string | null | undefined;
};

const SignInWindow = ({ changeUser, tenantAccountName }: SignInWindow) => {
  let dqoLoginState: DqoLoginState = dqoLoginStore.getState();

  const classes = useStyles();
  const [accountName, setAccountName] = useState(tenantAccountName ?? "");
  const [accountNameError, setAccountNameError] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (tenantAccountName != null) {
      setAccountName(tenantAccountName);
    }
  }, [accountName]);

  const handleEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const emailAddress = event.target.value;
    setEmailAddress(emailAddress);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPassword(password);
  };

  const lookupTenantId = () : Promise<String> => {
    auth.tenantId = null;
    if (accountName === "" || accountName == null) {
      setAccountNameError("");
      return new Promise(function(resolve, reject) {
          resolve("");
        });
    }

    return new Promise(function(resolve, reject) {
      setIsLoading(true);
      UserApiClient.lookupTenantId(accountName)
          .then(response => {
            auth.tenantId = response.data;
            setIsLoading(false);
            setAccountNameError("");
            resolve(response.data);
          })
          .catch(() => {
            auth.tenantId = null;
            setIsLoading(false);
            setAccountNameError("Account not found");
            reject("Account not found");
          });
    });
  };

  const signInWithAuthProvider = (providerId: string) => {
    lookupTenantId()
      .then(() => {
        authentication
        .signInWithAuthProvider(providerId)
        .then(() => {
          changeUser(auth.currentUser);
        })
          .catch((reason) => {
            if (reason !== undefined) {
              const message = typeof reason === 'string' ? reason : reason.message;
  
              Toast.add({
                text: message,
                color: "#fd3744",
                autohide: true,
                delay: 5000,
              });
            }
          })
        .finally(() => {
  
        });
      });
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target;
    const foundErrors =
      validate(
        {
          emailAddress: target['email'].value,
          password: target['password'].value,
        },
        {
          emailAddress: constraints.emailAddress,
          password: constraints.password,
        }
      );

    setErrors(foundErrors);

    if (!foundErrors) {
      lookupTenantId()
      .then(() => {
        setIsLoading(true);
        authentication
          .signIn(target['email'].value, target['password'].value)
          .catch(reason => {
            if (reason !== undefined) {
              const message = reason instanceof String ? reason : reason.message;
  
              Toast.add({
                text: message,
                color: '#fd3744',
                autohide: true,
                delay: 5000
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        });
    }
  }

  return (
    <div className={classes.signWrapper}>
      <Paper elevation={3}>
        <div className={classes.signGradient}></div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            DQOps Login
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="account"
            label="Account name"
            placeholder="Enter here the account name or leave empty for free accounts"
            error={accountNameError?.length! > 0}
            helperText={accountNameError?.length! > 0 ? accountNameError : ''}
            name="account"
            value={accountName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAccountName(event.target.value);
            }}
          />
          <SignInMedia
            signInWithAuthProvider={signInWithAuthProvider}
            handleEmailAddressChange={handleEmailAddressChange}
            handlePasswordChange={handlePasswordChange}
          />
          <span className={classes.spanText}>OR</span>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              error={errors?.emailAddress?.length! > 0}
              helperText={errors?.emailAddress?.length! > 0 ? errors?.emailAddress.join(', ') : ''}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              error={errors?.password?.length! > 0}
              helperText={errors?.password?.length! > 0 ? errors?.password.join(', ') : ''}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <span className={classes.spanText}>
              I have read and I accept the{" "}
              <u>
                <a
                  className={classes.spanText}
                  href="https://dqops.com/terms-and-conditions/"
                >
                  Terms and Conditions
                </a>
              </u>{", "}
              <u>
                <a
                  className={classes.spanText}
                  href="https://dqops.com/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </u>{" and  "}
              <u>
                <a
                    className={classes.spanText}
                    href="https://dqops.com/cookies-policy/"
                >
                  Cookies Policy
                </a>
              </u>
              .
            </span>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isLoading ? <Loader /> : 'Sign In'}
            </Button>
            { (accountName === null || accountName === '') &&
            <div className={classes.spanWrapper}>
              <span className={classes.spanText}>
                {/*eslint-disable-next-line*/}
                <a className={classes.signLinkAuth} href="/passwordreset">
                  Forgot password?
                </a>
              </span>
              <span className={classes.spanText}>
                &nbsp; · &nbsp;
                <Link className={classes.signLinkAuth} to="/registration">
                  Don't have an account? Sign Up
                </Link>
              </span>
            </div>
           }
          </form>
        </div>
      </Paper>
      <div className={classes.terms}>
        <span>
          {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/terms-and-conditions/">
            Terms and Conditions
          </a>
        </span>
        <span>
          &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/privacy-policy/">
            Privacy Policy
          </a>
        </span>
        <span>
          &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/cookies-policy/">
            Cookies Policy
          </a>
        </span>
      </div>
    </div>
  );
};
export default SignInWindow;
