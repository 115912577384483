
import { useState } from 'react';
import { useLocation } from 'react-router';

function useQuery<T>(param: string, defaultValue: T, useAll?: boolean, convert?: (arg0: string | string[]) => T) {
	const search = new URLSearchParams(useLocation().search);

	const stringValue = useAll ? search.getAll(param) : search.get(param);

	let value: T;
	if(stringValue) {
		value = convert ? convert(stringValue) : stringValue as unknown as T;
	} else {
		value = defaultValue;
	}

	const state = useState<T>(value ? value as unknown as T : defaultValue);

	return state;
}

export default useQuery;