import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { Toast } from 'components';
import constraints from 'constraints';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import authentication from 'services/authentication';
import useStyles from 'styles';
import validate from 'validate.js';
import { auth } from 'firebaseSingleton';
import { UserApiClient } from "../../../../services/apiClient";
import { dqoLoginStore } from "../../../../state/dqologin/DqoLoginStore";
import { DqoLoginState } from "../../../../state/dqologin/DqoLoginState";

type ResetPasswordWindow = {}

const ResetPasswordWindow = () => {
  let dqoLoginState: DqoLoginState = dqoLoginStore.getState();

  const [errors, setErrors] = useState<{emailAddress: string[], password: string[], passwordConfirmation: string[]} | null>(null);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [incorrectEmail, setIncorrectEmail] = useState<boolean>(false);
  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    if (dqoLoginState !== null) {
      setAccountName(dqoLoginState.accountName ?? '');
    }
  }, [accountName]);

  const lookupTenantId = () : Promise<String> => {
    auth.tenantId = null;
    if (accountName === "" || accountName == null) {
      setAccountNameError("");
      return new Promise(function(resolve, reject) {
          resolve("");
        });
    }

    return new Promise(function(resolve, reject) {
//      setIsLoading(true);
      UserApiClient.lookupTenantId(accountName)
          .then(response => {
            auth.tenantId = response.data;
//            setIsLoading(false);
            setAccountNameError("");
            resolve(response.data);
          })
          .catch(() => {
            auth.tenantId = null;
//            setIsLoading(false);
            setAccountNameError("Account not found");
            reject("Account not found");
          });
    });
  };

  const onClickSendNewPassword = (event: SyntheticEvent) => {
    event.preventDefault();

    const hasErrors = validate(
      {emailAddress: event.target['email'].value},
      {emailAddress: constraints.emailAddress}
    )
    setErrors(hasErrors);

    auth.tenantId = null;
    if (!hasErrors && !emailSent) {
      lookupTenantId()
      .then(() => {
        authentication.resetPassword(event.target['email'].value)
          .then(() => {
            setIncorrectEmail(false);
            setEmailSent(true);
          })
          .catch(reason => {
            if (reason !== undefined) {
              setEmailSent(false);
              setIncorrectEmail(true);
            }
          });
      });
    }

    if (emailSent && password && password === passwordConfirmation) {
      lookupTenantId()
      .then(() => {
        authentication.changePassword(password).then(() => {
          Toast.add({
            text: 'Set reset password',
            color: "#00FF00",
            autohide: true,
            delay: 5000,
          });
        }).catch(reason => {
          console.log(reason);
          Toast.add({
            text: 'Set reset password failed',
            color: "#fd3744",
            autohide: true,
            delay: 5000,
          });
        });
      });
    }
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handlePasswordConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
  };

  return (
    <div className={classes.signWrapper}>
      <Paper elevation={3}>
        <div className={classes.signGradient}></div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="account"
            label="Account name"
            placeholder="Enter here the account name or leave empty for free accounts"
            error={accountNameError?.length! > 0}
            helperText={accountNameError?.length! > 0 ? accountNameError : ''}
            name="account"
            value={accountName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAccountName(event.target.value);
            }}
          />
          <span style={{marginTop: '15px'}}>
                Enter the email address connected to your DQOps account.
              </span>
          <form
            className={classes.form}
            onSubmit={(event) => {
              onClickSendNewPassword(event)
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              error={errors?.emailAddress?.length! > 0}
              helperText={errors?.emailAddress?.length! > 0 ? errors?.emailAddress.join(', ') : ''}
              name="email"
              autoComplete="email"
              autoFocus
            />
            {emailSent &&
              <span>
                Your password reset link has been sent.
              </span>}
            {incorrectEmail &&
              <span style={{color: '#fd3744'}}>
                  Please check if the entered email address is correct.
              </span>}
            {emailSent && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  error={errors?.password?.length! > 0}
                  helperText={errors?.password?.length! > 0 ? errors?.password?.join(', ') : ''}
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  onChange={handlePasswordChange}
                  value={password}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Confirm password"
                  type="password"
                  autoComplete="password"
                  error={errors?.passwordConfirmation?.length! > 0}
                  helperText={errors?.passwordConfirmation?.length! > 0 ? errors?.passwordConfirmation?.join(', ') : ''}
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                />
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send new password
            </Button>
          </form>
        </div>
      </Paper>
      <div className={classes.terms}>
            <span>
              {/*eslint-disable-next-line*/}
              <a className={classes.signLink} href="https://dqops.com/terms-and-conditions/">
                Terms and Conditions
              </a>
            </span>
        <span>
              &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/privacy-policy/">
                Privacy Policy
              </a>
            </span>
        <span>
              &nbsp; · &nbsp; {/*eslint-disable-next-line*/}
          <a className={classes.signLink} href="https://dqops.com/cookies-policy/">
                Cookies Policy
              </a>
            </span>
      </div>
    </div>
  );
}

export default ResetPasswordWindow;