import React from "react";
import "./index.css";
import ReactDOM from "react-dom";
import "typeface-roboto";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { BrowserRouter as Router } from "react-router-dom";
import { parse, stringify } from 'query-string';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

firebase.auth().onIdTokenChanged((a: firebase.User | null): any => {
    if (a !== null) {
        a.getIdToken().then((token: string) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        });
    } else {
        if (axios.defaults.headers && axios.defaults.headers.common && axios.defaults.headers.common.delete) {
            axios.defaults.headers.common.delete("Authorization");
        }
  }
});

const GoogleTagManager = () => {
  return (
    <>
      {/* Google Tag Manager */}
      <script>
        {`
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MCK44NN');`}
      </script>
      {/* End Google Tag Manager */}
    </>
  );
};

ReactDOM.render(
  <Router>
      <GoogleTagManager />
      <QueryParamProvider 
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}>
          <App />
      </QueryParamProvider>
  </Router>,
  document.getElementById("root")
);

// If  you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();