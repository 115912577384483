import React from 'react';
import { IIcon } from 'types/interfaces';

interface Icon extends IIcon {
  iconTypes: object;
  type: string;
  size: "sm" | "md" | "lg";
}

const Icon = ({ type, size, iconTypes }: Icon) => {
  const sizes = {
    lg: 7.8125,
    md: 10,
    sm: 3.90625,
  };

  return (
    <img
      style={{
        height: (250 / sizes[size]).toFixed(2) + "px",
        width: (250 / sizes[size]).toFixed(2) + "px",
      }}
      src={iconTypes[type]}
    />
  );
};

Icon.defaultProps = {
  size: "md",
};

export default Icon;
export { default as MenuIcon } from './MenuIcon';
export { default as BaseIcon } from './BaseIcon';
