import React, { Component, KeyboardEvent, FocusEvent, useEffect } from 'react';

import PropTypes from 'prop-types';

import validate from 'validate.js';
import moment from 'moment';

import {
  TextField,
  IconButton,
} from '@material-ui/core';

import { Lock as LockIcon, Edit as EditIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';

import constraints from 'constraints';
import authentication from 'services/authentication';
import { TenantApiClient } from 'services/apiClient';
import FileExchangeAPIKey from 'components/FileExchangeAPIKey';
import LookerStudioAPIKey from 'components/LookerStudioAPIKey';
import Toast from 'components/Toast';
import Field from 'pages/Account/Field';
import {AxiosResponse} from "axios";

const initialState = {
  showingField: "",
  password: "",
  dqoApiKey: "",
  passwordConfirmation: "",
  performingAction: false,
  errors: null,
};

class SecurityTab extends Component {
  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  static propTypes = {
    // Properties
    userData: PropTypes.object,
    user: PropTypes.object,
  };

  showField = (fieldId: string) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };
  hideFields = (event: FocusEvent<Element> | null, callback?: () => void) => {
    this.setState(
      {
        showingField: "",
        password: "",
        passwordConfirmation: "",
        errors: null,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };
  changeField = (fieldId: string) => {
    switch (fieldId) {
      case "password":
        const { password } = this.state as any;

        const errors = validate(
          {
            password: password,
          },
          {
            password: constraints.password,
          }
        );

        if (errors) {
          this.setState({
            errors: errors,
          });

          return;
        }

        this.setState(
          {
            errors: null,
          },
          () => {
            this.showField("password-confirmation");
          }
        );
        return;

      case "password-confirmation":
        this.changePassword();
        return;
      // case "api":
      //   const { api } = this.state as any;
      //
      //   const apiErrors = validate(
      //     {
      //       api: api,
      //     },
      //     {
      //       api: constraints.api,
      //     }
      //   );
      //
      //   if (apiErrors) {
      //     this.setState({
      //       errors: apiErrors,
      //     });
      //
      //     return;
      //   }
      //
      //   this.setState(
      //     {
      //       errors: null,
      //     },
      //     () => {
      //       this.changeApi();
      //     }
      //   );
      //   return;
      default:
        return;
    }
  };
  changePassword = () => {
    const { password, passwordConfirmation } = this.state as any;

    const errors = validate(
      {
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changePassword(password)
              .then(() => {
                this.hideFields(null, () => {
                  Toast.add({
                    text: 'Changed password',
                    color: "#00FF00",
                    autohide: true,
                    delay: 5000,
                  });
                });
              })
              .catch((reason) => {
                //       const code = reason.code;
                const message = reason.message;

                Toast.add({
                  text: message,
                  color: "#fd3744",
                  autohide: true,
                  delay: 5000,
                });
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };
  // changeApi = () => {
  //   const { api } = this.state as any;
  //
  //   const errors = validate(
  //     {
  //       api: api,
  //     },
  //     {
  //       api: constraints.api,
  //     }
  //   );
  //
  //   if (errors) {
  //     this.setState({
  //       errors: errors,
  //     });
  //
  //     return;
  //   }
  //
  //   this.setState(
  //     {
  //       errors: null,
  //     },
  //     () => {
  //       this.setState(
  //         {
  //           performingAction: true,
  //         },
  //         () => {
  //           TenantApiClient
  //             .changeTenantApiKey(api)
  //             .then((response) => {
  //               this.hideFields(null, () => {
  //                 Toast.add({
  //                   text: 'Changed password',
  //                   color: "#00FF00",
  //                   autohide: true,
  //                   delay: 5000,
  //                 });
  //               });
  //             })
  //             .catch((reason) => {
  //               //       const code = reason.code;
  //               const message = reason.message;
  //
  //               Toast.add({
  //                 text: message,
  //                 color: "#fd3744",
  //                 autohide: true,
  //                 delay: 5000,
  //               });
  //             })
  //             .finally(() => {
  //               this.setState({
  //                 performingAction: false,
  //               });
  //             });
  //         }
  //       );
  //     }
  //   );
  // };
  handleKeyDown = (event: KeyboardEvent<Element>, fieldId: string) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      this.hideFields(null);
    } else if (key === "Enter") {
      this.changeField(fieldId);
    }
  };
  handleApiChange = (event: any) => {
    if (!event) {
      return;
    }

    const api = event.target.value;

    this.setState({
      api: api,
    });
  };
  handlePasswordChange = (event: any) => {
    if (!event) {
      return;
    }

    const password = event.target.value;

    this.setState({
      password: password,
    });
  };
  handlePasswordConfirmationChange = (event: any) => {
    if (!event) {
      return;
    }

    const passwordConfirmation = event.target.value;

    this.setState({
      passwordConfirmation: passwordConfirmation,
    });
  };
  getApiKey() {
    TenantApiClient.getTenantApiKeyForFileExchange().then((response: AxiosResponse<string>) => {
      this.setState({
        dqoApiKey: response.data ?? "",
      });
    });
  }
  render() {
    // Properties
    const { userData } = this.props as any;

    const {
      showingField,
      password,
      dqoApiKey,
      passwordConfirmation,
      performingAction,
      errors,
    } = this.state as any;

    const hasChangedPassword = userData && userData.lastPasswordChange;

    const primaryPassword = ['password', 'password-confirmation'].includes(showingField)
      ? undefined
      : 'Password';

    const secondaryPassword = ['password', 'password-confirmation'].includes(showingField)
      ? undefined
      : hasChangedPassword
        ? `Last changed ${moment(userData.lastPasswordChange.toDate()).format('LL')}`
        : "Never changed"

    return (
      <>
        <Field icon={<LockIcon />} style={{minHeight: '95px'}}>
          <Field.Main primary={primaryPassword} secondary={secondaryPassword}>
            {showingField === "password" && (
              <TextField
                autoComplete="new-password"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={
                  errors && errors.password
                    ? errors.password[0]
                    : "Press Enter to change your password"
                }
                label="Password"
                required
                type="password"
                value={password}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={(event: KeyboardEvent<HTMLDivElement>) =>
                  this.handleKeyDown(event, "password")
                }
                onChange={this.handlePasswordChange}
                style={{width: '80%'}}
              />)}

            {showingField === "password-confirmation" && (
              <TextField
                autoComplete="new-password"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                helperText={
                  errors && errors.passwordConfirmation
                    ? errors.passwordConfirmation[0]
                    : "Press Enter to change your password"
                }
                label="Password confirmation"
                required
                type="password"
                value={passwordConfirmation}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={(event) =>
                  this.handleKeyDown(event, "password-confirmation")
                }
                onChange={this.handlePasswordConfirmationChange}
                style={{width: '80%'}}
              />)}
          </Field.Main>

          <Field.Action title='Change'>
            <IconButton
              disabled={performingAction}
              onClick={() => this.showField("password")}
            >
              <EditIcon />
            </IconButton>
          </Field.Action>
        </Field>

        <FileExchangeAPIKey showHideButtonDisabled={performingAction} style={{minHeight: '145px'}} />
        <LookerStudioAPIKey showHideButtonDisabled={performingAction} style={{minHeight: '145px'}} />
      </>
    );
  }
}

export default SecurityTab;
