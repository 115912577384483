import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDashboard } from "../../../contexts/dashboard.context";
import { IDashboard } from "../../../types/interfaces";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteConfirmDialog from "../../../components/Dashboards/DeleteConfirmDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  dashboardCard: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: theme.spacing(30),
    marginBottom: theme.spacing(1),
  },
  header: {
    position: "relative",
    paddingRight: theme.spacing(3),
  },
  deleteButton: {
    position: "absolute",
    right: theme.spacing(-1.5),
    top: "50%",
    transform: "translateY(-50%)",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: theme.spacing(1.75),
    marginRight: theme.spacing(1),
  },
  body2: {
    fontSize: theme.spacing(1.75),
  },
}));

const MyDashboards = () => {
  const classes = useStyles();
  const { selectedDashboards, removeDashboard } = useDashboard();
  const [dashboard, setDashboard] = useState<IDashboard>();
  const [showModal, setShowModal] = useState(false);

  const handleDelete = (dashboard: IDashboard) => {
    setDashboard(dashboard);
    setShowModal(true);
  };

  const onConfirmDelete = () => {
    removeDashboard(dashboard);
  };

  return (
    <div>
      <Typography className={classes.heading} variant="h1" component="h1">
        Dashboards
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        {selectedDashboards.map((dashboard: IDashboard, index: number) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Paper className={classes.dashboardCard}>
              <img className={classes.image} src={dashboard.screenshot} />
              <Box className={classes.header}>
                <Typography
                  className={classes.title}
                  variant="h2"
                  component="h2"
                >
                  {dashboard.title}
                </Typography>
                <IconButton
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={() => handleDelete(dashboard)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box className={classes.flexWrapper}>
                <Typography className={classes.label}>Size:</Typography>
                <Typography className={classes.body2}>
                  {dashboard.width}X{dashboard.height}
                </Typography>
              </Box>
              <Box className={classes.flexWrapper}>
                <Typography className={classes.label}>Category:</Typography>
                <Typography className={classes.body2}>
                  {dashboard.category.split("/")[0]}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <DeleteConfirmDialog
        onClose={() => setShowModal(false)}
        open={showModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

export default MyDashboards;
