import { ApiKeyRequestActions } from "./ApiKeyRequestActions";
import { apiKeyRequestDispatcher } from "./ApiKeyRequestDispatcher";
import ApiKeyRequestActionTypes from "./ApiKeyRequestActionTypes";

export class ApiKeyRequestActionCreator {
  static requestApiKey(apiKeyRequest: string): void {
    apiKeyRequestDispatcher.dispatch(new ApiKeyRequestActions(ApiKeyRequestActionTypes.API_KEY_REQUESTED, apiKeyRequest));
  }

  static apiKeyPicked(): void {
    apiKeyRequestDispatcher.dispatch(new ApiKeyRequestActions(ApiKeyRequestActionTypes.API_KEY_PICKED));
  }
}
