import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Close as CloseIcon } from '@material-ui/icons';

type AboutDialog = {
  dialogProps: any;
  user?: any;
};

const AboutDialog = ({ dialogProps, user }: AboutDialog) => {
  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const version = process.env.REACT_APP_VERSION;

  if (!user && !version) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="xs" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">About</Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          {version && (
            <ListItem>
              <ListItemText primary="Version" secondary={version} />
            </ListItem>
          )}

          {user && (
            <ListItem>
              <ListItemText primary="UID" secondary={user.uid} />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default AboutDialog;
