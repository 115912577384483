import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  AccountPage,
  AdminPage,
  NotFoundPage,
  ErrorPage,
  RequestApiKey,
  WrongTenant,
  IdentityProviderLogin,
  DashboardGovernance,
  DashboardOperational,
  DashboardDetails
} from "pages";
import useStyles from "styles";
import { useInterceptor } from "hooks";
import Dashboards from "../../../pages/Dashboards/Dashboards";
import MyDashboards from "../../../pages/Dashboards/MyDashboards";
import Home from "pages/Home";

type MainBody = {
  user: any;
  userData: any;
  onDeleteAccountClick: () => void;
  roles: any;
  style?: React.CSSProperties;
};

const MainBody = ({
  user,
  userData,
  onDeleteAccountClick,
  roles,
  style,
}: MainBody) => {
  const [serverError, setServerError] = useState<boolean>(false);

  useInterceptor(
    "response",
    "ROUTER_ERROR_INTERCEPTOR",
    (error: any) => {
      if (!error.response || !error.response.status) {
        setServerError(true);
      }

      return error;
    },
    true
  );

  const classes = useStyles();

  // ROUTER INFO HERE
  return (
    <main className={classes.content} style={style}>
      <Switch>
        {serverError && <Route path="/" component={ErrorPage} />}
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/registration">
          <Redirect to="/" />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route path="/admin">
          {user && roles.includes("admin") ? (
            <AdminPage />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route exact path="/governance">
          <DashboardGovernance />
        </Route>
        <Route exact path="/operational">
          <DashboardOperational />
        </Route>
        <Route exact path="/details">
          <DashboardDetails />
        </Route>
        <Route exact path="/dashboards">
          <Dashboards />
        </Route>
        <Route exact path="/my-dashboards">
          <MyDashboards />
        </Route>

        <Route exact path="/requestapikey/:apiKeyRequest">
          <RequestApiKey />
        </Route>
        <Route path="/wrongtenant">
          <WrongTenant />
        </Route>
        <Route path="/login">
          <IdentityProviderLogin />
        </Route>
        <Route path="/logout">
          <IdentityProviderLogin />
        </Route>
        <Route path="/account">
          <AccountPage
            user={user}
            userData={userData}
            onDeleteAccountClick={onDeleteAccountClick}
          />
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
    </main>
  );
};

export default MainBody;
