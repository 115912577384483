import { createStyles, makeStyles, Theme, darken } from '@material-ui/core/styles';
import TemplateBackground from 'illustrations/transparent.png';

export const colors = {
  default: {
    darkest: "#10577f",
    darker: "#029a80",
    dark: "#5f9cec",
    main: "#62acff",
    light: "#7ccbfd",
    lighter: "#d8eae9",
    lightest: "#edf7f6",
  },
  success: {
    darker: "#006336",
    dark: "#0e8d54",
    main: "#4caf55",
    light: "#77c27e",
    lighter: "#74cca4",
    lightest: "#eff7ef",
  },
  danger: {
    main: "#e3170a",
  },
  background: {
    ultralight: "#ffffff",
    lightest: "#faefef",
    catskillWhite: "#F9FCFB",
    lighter: "#eeeeee",
    light: "#bdbdbd",
    main: "#888888",
    darker: "#6c6c6c",
    darkest: "#333333",
    ultraDark: "#000000",
  },
  scrollbar: {
    main: "#1b252d",
    light: "#2f3b47",
  },
  dropZone: {
    light: "#f5f5f5",
    main: "#029a80",
    dark: "#4a5568",
  },
  pagination: {
    main: "#05960b29",
    dark: "#6d758d38",
  },
  mainbar: {
    background: '#ffffff'
  },
  navbar: {
    background: '#029a80',
    active: '#999999',
  },
  text: {
    light: '#999999',
    dark: '#2f2f2f'
  },
  preloader: {
    main: "#30365f",
  },
  arrow: {
    main: "#cbe1ef",
  },
  icon: {
    search: "#4c4c4c",
  },

  dark: '#4c4c4c',
  light: '#999999',
  highlights: '#029a80'
};

export const defaults = {
  radius: '8px',
  padding: '0.75rem',
  spacing: '2rem',
  margin: '1rem',
  transition: '500ms ease',
  fonts: {
    primary: 'Lato, sans-serif',
    secondary: 'Carme, sans-serif'
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(4),
      display: "flex",
      "@media screen and (max-width: 600px)": {
        padding: 0,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
    },
    drawer: {
      width: 240,
      flexShrink: 0,
      display: "none",
    },
    drawerGallery: {
      display: "none",
    },
    drawerPaper: {
      width: 240,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      paddingTop: theme.spacing(6),
      '@media screen and (max-width: 600px)': {
        margin: 0,
        padding: '8px',
      },
      transition: '500ms padding-left ease'
    },
    navbarEnter: {
      width: 48,
    },
    navbarEnterActive: {
      width: 220,
      transition: 'width 500ms ease'
    },
    navbarExit: {
      width: 220,
    },
    navbarExitActive: {
      width: 48,
      transition: 'width 500ms ease'
    },
    navbar: {
      transition: 'width 500ms ease',
      '@media screen and (max-width: 600px)': {
        position: 'fixed',
        zIndex: 15000,
        bottom: '0px',
        width: '100%',
        padding: '0.25rem',
        height: '64px',
        backgroundColor: colors.navbar.background,
      },
      '@media screen and (min-width: 600px)': {
        height: "100vh",
        marginTop: '48px',
      },
    },
    navbarHidden: {
      overflowX: 'hidden'
    },
    navbarButtonExpand: {
      '@media screen and (max-width: 600px)': {
        display: 'none !important'
      }
    },
    navbarExpanded: {
      width: 200
    },
    navbarNav: {
      listStyle: 'none',
      padding: 0,
      paddingTop: 48,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      "@media screen and (max-width: 600px)": {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 0
      },
    },
    navbarCover: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: colors.text.dark,
      opacity: 0.75,
      zIndex: 2,
      maxWidth: '100%'
    },
    navItem: {
      width: "100%",
      color: 'white',
      display: 'flex',
      fontFamily: defaults.fonts.primary,
      fontWeight: 'bold',
      padding: '8px',
      minWidth: 'unset',
      "@media screen and (max-width: 600px)": {
        flexDirection: "row",
        padding: '0 8px',
      },
      "& .MuiListItemIcon-root": {
        minWidth: "initial",
        paddingLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    navItemExpanded: {
      justifyContent: 'start'
    },
    navLink: {
      display: "flex",
      alignItems: "center",
      paddingLeft: '8px',
      height: "64px",
      color: "white",
      width: '100%',
      fontFamily: defaults.fonts.primary,
      textDecoration: "none",
      // filter: "grayscale(100%) opacity(1)",
      transition: "100ms",
      "&:hover": {
        // filter: "grayscale(0%) opacity(1)",
        background: colors.navbar.active,
        color: colors.background.ultralight,
      },
      "@media screen and (max-width: 600px)": {
        justifyContent: "center",
        paddingLeft: 0
      },
    },
    navLinkCurrent: {
      display: "flex",
      alignItems: "center",
      height: "64px",
      fontFamily: defaults.fonts.primary,
      textDecoration: "none",
      transition: "100ms",
      background: colors.navbar.active,
      color: colors.background.ultralight,
      "@media screen and (max-width: 600px)": {
        justifyContent: "center",
      },
    },
    linkText: {
      display: "none",
      marginLeft: "1rem",
    },
    image: {
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media screen and (max-width: 600px)": {
        margin: "32px 15px 32px 15px",
      },
    },
    mainBar: {
      minHeight: '48px !important',
      maxHeight: '48px !important',
      background: colors.mainbar.background
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      textAlign: "center",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: "51px",
      background: "#029a80",
      "& .MuiCircularProgress-root": {
        color: theme.palette.common.white
      }
    },
    typografiText: {
      color: "textSecondary",
      textAlign: "center",
    },

    /* SHARED */
    pageWrapper: {
      padding: theme.spacing(2, 3),
    },
    pageHeader: {
      color: colors.text.light,
      fontSize: defaults.spacing,
    },
    pageSubtitle: {
      color: colors.text.dark,
      marginTop: 0,
      marginBottom: defaults.spacing,
      fontFamily: defaults.fonts.secondary
    },
    defaultButton: {
      backgroundColor: colors.highlights,
      color: 'white',
      width: 'fit-content',
      padding: defaults.padding,
      border: 'unset',
      borderRadius: defaults.radius,
      fontFamily: defaults.fonts.primary,
      transition: defaults.transition,
      cursor: 'pointer',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: darken(colors.highlights, 0.1)
      }
    },
    whiteButton: {
      color: 'white',
      backgroundColor: colors.light,
      '&:hover': {
        backgroundColor: darken(colors.light, 0.1)
      }
    },
    codeBlock: {
      backgroundColor: colors.dark,
      position: 'relative',
      color: 'white',
      padding: '1rem',
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      tabSize: 2,
      display: 'flex',
      flexFlow: 'row nowrap',
      overflow: 'auto'
    },
    codeBlockCopy: {
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      padding: '0.5rem',
      transition: 'opacity 0.25s ease',
    },
    codeBlockCopyHidden: {
      opacity: '0'
    },
    codeBlockBash: {
      color: colors.light,
      height: '100%',
      width: 'fit-content'
    },
    pageModal: {
      display: 'none',
      position: 'fixed',
      zIndex: 500,
      padding: '2rem',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.4)',
      overflowY: 'visible'
    },
    pageModalTitle: {
      fontSize: '0.75rem',
      width: '100%',
    },
    pageModalBody: {
      backgroundColor: 'white',
      border: `2px solid ${colors.dark}`,
      borderRadius: defaults.radius,
      padding: `0 ${defaults.padding}`,
      width: '80%',
      height: '80%',
      overflow: 'auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      overflowY:'auto',
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
      },
      '&::-webkit-scrollbar:vertical': {
          width: '12px',
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, .5)',
          borderRadius: '10px',
          border: '2px solid #ffffff',
      },
      '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#ffffff',
      },

      ['@media (max-height: 700px)']: {
        height: '90%',
        width: '90%'
      },
    },
    popupGeneratedImage: {
      maxWidth: '75%',
      maxHeight: '75%'
    },
    generateTile: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '&> *': {
        margin: '1rem'
      }
    },
    generateSelect: {
      color: colors.dark,
      '&.Mui-checked': {
        color: colors.dark
      }
    },

    /* PAGE TABS */
    tabWrapper: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      margin: '1rem'
    },
    tabSelectWrapper: {
      width: '80%',
      borderBottom: `2px solid ${colors.light}`,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-evenly',

      [theme.breakpoints.down(1250)]: {
        width: '100%',
        overflowX: 'auto'
      }
    },
    tab: {
      borderRadius: `${defaults.radius} ${defaults.radius} 0 0`,
      padding: defaults.padding,
      maxWidth: 'fit-content',
      cursor: 'pointer',
      transition: defaults.transition,
      backgroundColor: 'white',
      minWidth: '240px',
      '&:hover': {
        backgroundColor: darken('#fff', 0.1)
      },

      [theme.breakpoints.down(1250)]: {
        minWidth: 'unset',
        padding: '0.75rem 0.25rem',
        margin: '0 0.25rem'
      }
    },
    tabActive: {
      backgroundColor: colors.highlights,
      color: 'white',
      '&:hover': {
        backgroundColor: darken(colors.highlights, 0.1)
      }
    },
    tabTitle: {
      fontFamily: defaults.fonts.primary,
      fontSize: '1.25rem',
      margin: 0,
      padding: 0,
      textAlign: 'center'
    },
    activeTabWrapper: {
      width: '100%',
      height: '100%',
      paddingTop: defaults.spacing,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center'
    },

    makeImageTabWrapper: {
      display: 'grid',
      width: '80%',
      gridTemplateColumns: '1fr 1fr',
      gridGap: defaults.spacing,

      [theme.breakpoints.down(1250)]: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        width: '100%'
      }
    },
    makeImageTab: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      minWidth: '100%',
      maxWidth: '100%',
      height: '100%'
    },
    makeImageFill: {
      gridColumnStart: 1,
      gridColumnEnd: 3,

      display: 'flex',
      justifyContent: 'center'
    },
    makeImageBorder: {
      border: `1px solid ${colors.dark}`,
      borderRadius: defaults.radius
    },
    makeImagePopupTitle: {
      color: colors.light,
      marginTop: 0
    },
    makeImagePopupButtons: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 'auto',
    },
    makeImagePopupButton: {
      margin: defaults.margin
    },

    /* ENTER VALUES MANUALLY */
    manualGeneratedDocumentsBox: {
      width: '100%',
      padding: defaults.padding,
    },
    manualGeneratedDocumentsTitle: {
      textAlign: 'center',
      fontWeight: 600,
    },
    manualLastGeneratedWrapper: {
      width: '100%',
      display: 'flex',
      padding: defaults.padding,
      overflowX: 'auto',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
        height: '9px',
        transition: '0.2s ease all',
        backgroundColor: colors.background.ultralight,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.scrollbar.main,
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: colors.scrollbar.light,
        borderRadius: '10px',
      },
    },
    manualRecentlyUsedWrapper: {
      maxWidth: '95%',
      width: 'auto',
      display: 'flex',
      padding: defaults.padding,
      overflowX: 'auto',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
        height: '9px',
        transition: '0.2s ease all',
        backgroundColor: colors.background.ultralight,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.scrollbar.main,
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: colors.scrollbar.light,
        borderRadius: '10px',
      },
    },
    manualGeneratedImageWrapper: {
      padding: '3px',
      marginRight: '25px',
      display: 'inline-block',
      minWidth: '180px',
      maxWidth: '180px',
      height: '112px',
      border: `1px solid ${colors.light}`,
      borderRadius: defaults.radius,
    },
    manualGeneratedImage: {
      height: '100%',
      width: '100%',
      marginBottom: '4px',
      objectFit: 'contain',
    },
    manualRecordMenuWrapper: {
      padding: '2rem',
      paddingBottom: '0'
    },
    manualRecordMenu: {
      minWidth: '200px',
      width: 'fit-content',
      padding: '8px',
      borderRadius: defaults.radius,
      border: `1px solid ${colors.dark}`,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    manualRecordMenuIcon: {
      color: 'black'
    },
    manualRecordMenuControls: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    manualRecordMenuSection: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center'
    },
    manualRecordMenuControl: {
      padding: 0
    },

    renderImageRoot: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
    },
    renderedImageWrapper: {
      position: 'relative',
      height: 'auto',
      margin: '25px',
      marginTop: '0',
      marginBottom: '0'
    },
    renderedImageBorderWrapper: {
			display: 'flex',
			flexFlow: 'column nowrap',
			position: 'absolute',
    },
    renderedImageBorderTitle: {
      height: 20,
      padding: 5,
      backgroundColor: colors.dark,
      color: 'white',
      borderRadius: `${defaults.radius} ${defaults.radius} 0 0`,
      alignSelf: 'flex-end',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center'
    },
    renderedImageBorder: {
      border: `3px dashed ${colors.dark}`
    },
    renderedImage: {
      maxWidth: '100%',
      maxHeight: '100%',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease',
      border: `1px solid ${colors.dark}`,
      borderRadius: defaults.radius
    },
    renderImageTemplateBanner: {
      position: 'absolute',
      backgroundColor: `${colors.dark}A0`, // bake opacity into the colour
      top: '50%',
      height: '100px',
      width: 'calc(100% + 30px)',
      left: '50%',
      zIndex: 499,
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center'
    },
    renderImageTemplatePreloader: {
      position: 'absolute',
      margin: 0,
      padding: '50px 30px',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    renderImageTemplateText: {
      width: '100%',
      color: 'white',
      padding: '5px',
      textAlign: 'center',
      zIndex: 500
    },
    renderImageActionText: {
      color: colors.default.main,
      textDecoration: 'underline',
      marginRight: '25px',
      cursor: 'pointer',
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        color: 'black',
      },
    },

    manualAccordion: {
      width: '100%',
      border: `1px solid ${colors.dark}`,
      borderRadius: `${defaults.radius} !important`,
      boxShadow: 'unset'
    },
    accordionTop: {
      borderRadius: `${defaults.radius} ${defaults.radius} 0 0 !important`,
      marginBottom: '0 !important',
      borderBottom: 'unset'
    },
    accordionBottom: {
      borderRadius: `0 0 ${defaults.radius} ${defaults.radius} !important`,
      marginTop: '0 !important'
    },
    accordionSwitch: {
      borderRadius: `0 0 0 0 !important`,
      borderBottom: 'unset',
      margin: '0 !important'
    },
    manualAccordionExpanded: {
      borderBottom: `1px solid ${colors.dark}`
    },
    manualAccordionDetails: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    manualAccordionField: {
      padding: defaults.padding,
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    withSeparator: {
      borderBottom: `1px solid ${colors.light}`
    },
    manualAccordionTextField: {
      flexBasis: '65%',
    },
    manualAccordionImageField: {
      flexBasis: '65%',
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: defaults.padding,
    },
    manualAccordionImage: {
      backgroundColor: '#CCC',
      width: 'auto',
      maxHeight: '128px',
      maxWidth: '256px',
      cursor: 'pointer',
      border: `1px solid ${colors.light}`,
      '&:hover': {
        border: '2px solid rgba(0, 0, 0, 0.87)' // same as material does it for text field
      }
    },
    manualAccordionImagePlaceholder: {
      opacity: '0.65'
    },
    manualAccordionImageName: {
      padding: 0,
      borderTop: `1px solid ${colors.light}`,
      width: '100%'
    },
    manualAccordionControls: {
      height: '100%',
      flexBasis: '35%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    },
    manualAccordionIcon: {
      padding: 0,
      color: '#000'
    },
    manualAccordionButton: {
      padding: '1rem',
    },

    /* CSV FILL */
    csvFillWrapper: {
      display: 'grid',
      width: '80%',
      gridTemplateColumns: '1fr',
      gridGap: defaults.spacing,
    },
    csvDragWrapper: {
      width: '100%',
      height: '100%',
      padding: '0.5rem'
    },
    csvDrag: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    csvStepLabel: {
      fontFamily: defaults.fonts.primary
    },
    csvTagField: {
      backgroundColor: '#FFF',

      '&:hover': {
        backgroundColor: darken('#FFF', 0.1)
      }
    },

    /* TEMPLATE GALLERY */
    dragDropImage: {
      width: '25%',
      height: 'auto'
    },
    dragDropText: {
      color: colors.text.light,
      fontSize: '1.25rem',
      fontWeight: 'bold'
    },
    templateGallerySelectController: {
      borderBottom: `2px solid ${colors.text.light}`,
      marginTop: '4rem',
      width: '80%',
      padding: defaults.padding,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    templateGalleryTemplateList: {
      width: '80%'
    },
    templateGalleryTemplate: {

    },

    dragNDropText: {
      textAlign: "center",
    },
    iconCopy: {
      fontSize: 15,
    },
    divider: {
      margin: "auto",
    },
    dropZone: {
      alignItems: "center",
      padding: "20px",
      borderRadius: '12px',
      border: `dashed 4px ${colors.text.light}`,
      backgroundColor: darken(colors.dropZone.light, 0.1),
      color: colors.text.light,
      transition: "all .24s ease-in-out",
      position: "relative",
      cursor: 'pointer'
    },
    dropZoneActive: {
      alignItems: "center",
      padding: "20px",
      border: `dashed 4px ${colors.text.light}`,
      borderRadius: '6px',
      borderColor: colors.text.dark,
      backgroundColor: colors.text.light,
      color: colors.text.dark,
      transition: "all .5s ease-in-out",
    },
    templateList: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-end",
      justifyContent: "space-evenly",
      height: "100%",
      width: "100%"
    },
    imageTrumbnail: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "1px solid #0000002e",
    },
    cardContainer: {
      maxWidth: 240,
      minWidth: 240,
      // position: "relative",
    },
    cardMedia: {
      height: 140,
    },
    boxRow: {
      display: 'flex',
      flexFlow: 'row wrap'
    },
    paperTemplate: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    rowItems: {
      marginBottom: 20,
      marginTop: 20,
    },
    templateInput: {
      minWidth: "calc(70% - 10px)",
      marginRight: 10,
    },
    buttonSave: {
      minWidth: "30%",
    },
    buttonsRight: {
      marginLeft: "auto",
    },
    headerBox: {
      boxSizing: "border-box",
    },
    recordMenuWrapper: {
      position: 'fixed',
      top: '3px',
      left: '47vw',
      zIndex: 1250,
    },
    recordMenu: {
      minWidth: '200px',
      width: 'fit-content',
      padding: '8px',
      borderRadius: '15px',
      backgroundColor: 'black',
      opacity: 0.5,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    recordMenuTagName: {
      color: 'white',
      margin: 0,
      minWidth: '70px',
      maxWidth: '65px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    recordMenuControls: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    recordMenuSection: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center'
    },
    recordMenuControl: {
      padding: 0
    },
    recordMenuIcon: {
      color: 'white'
    },
    templateButton: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      textDecoration: "none",
      margin: "10px",
      fontSize: "14px",
      textTransform: "uppercase",
      border: "1px solid " + colors.default.darker,
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      transition: "all 0.3s ease-in-out",
      borderRadius: "4px",
      //marginRight: "15px",
      cursor: "pointer",
      backgroundColor: colors.default.dark,
      color: "white",
      "&:hover": {
        backgroundColor: colors.default.main,
        border: "1px solid " + colors.default.main,
      },
    },
    templateButtonRef: {
      textDecoration: "none",
      margin: "10px 0",
      fontSize: "14px",
      textTransform: "uppercase",
      border: "none",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      transition: "all 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "55px",
      cursor: "pointer",
      backgroundColor: colors.success.main,
      color: "white",
      "&:hover": {
        backgroundColor: colors.success.light,
      },
    },
    templateButtonSave: {
      textDecoration: "none",
      marginTop: "15px",
      fontSize: "14px",
      textTransform: "uppercase",
      border: "1px solid " + colors.default.dark,
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      transition: "all 0.3s ease-in-out",
      borderRadius: "4px",
      backgroundColor: "white",
      cursor: "pointer",
      color: colors.default.dark,
      "&:hover": {
        backgroundColor: colors.default.main,
        color: "white",
      },
    },
    searchTemplateInputWrapper: {
      width: "100%",
      height: "42px",
      backgroundColor: "white",
      border: "2px solid #ccc",
      borderRadius: "4px",
    },
    parameterBlockWrapper: {
      width: "70%",
      "@media screen and (max-width: 1200px)": {
        width: "100%",
      },
    },
    gridTemplateItem: {
      paddingRight: "20px",
      paddingBottom: "20px",
    },
    inputGroupWrapper: {
      position: "relative",
      height: "50px",
      overflow: "hidden",
      paddingRight: "180px",
      "@media screen and (max-width: 1000px)": {
        paddingRight: "130px",
      },
      "@media screen and (max-width: 800px)": {
        paddingRight: "30px",
      },
    },
    templateSizeWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0",
      margin: "0",
    },
    inputTemplate: {
      width: "100%",
      height: "100%",
      paddingTop: "20px",
      border: "none",
      outline: " none",
      "&:focus  +  $labelInput $contentInputName, &:valid + $labelInput $contentInputName": {
        transform: "translateY(-150%)",
        fontSize: "10px",
        color: colors.default.dark,
        opacity: "1",
      },
      "&:focus + $labelInput::after, &:valid + $labelInput::after": {
        transform: "translateX(0%)",
      },
    },
    labelInput: {
      position: "absolute",
      bottom: "0px",
      left: "0%",
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      borderBottom: "1px solid black",
      "&::after": {
        content: `' '`,
        position: "absolute",
        width: "100%",
        height: "100%",
        borderBottom: "3px solid " + colors.default.dark,
        transform: "translateX(-100%)",
        left: "0px",
        top: "1px",
        transition: "all 0.3s ease",
      },
    },
    contentInputName: {
      position: "absolute",
      bottom: "5px",
      left: "0px",
      transition: "all 0.3s ease",
      transform: "translateY(-150%)",
      fontSize: "10px",
      color: colors.default.dark,
      opacity: "1",
    },
    headerWrapper: {
      justifyContent: "space-between",
      alignItems: 'center',
      display: "flex",
      flexDirection: "row",
      borderBottom: '1px solid black',
      textAlign: 'left'
    },
    headerActions: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    buttonActions: {
      marginRight: "20px",
      width: "230px",
    },
    buttonActionsLink: {
      textDecoration: "none",
    },
    thumbnail: {
      textAlign: "center",
      width: "100%",
      margin: "50px 0",
      overflow: "hidden",
      display: "inline-block",
    },
    imageTemplate: {
      display: "block",
      transition: "opacity 124ms linear",
      opacity: 1,
    },
    imageWrapper: {
      position: "relative",
      display: "inline-block",
      //background: colors.background.ultraDark,
      "&:hover": {
        "& $buttonThumbnail": {
          opacity: "1",
        },
        "& $imageTemplate": {
          opacity: 0.5,
        },
        "& $dropZoneWrapper": {
          opacity: 0.65,
        },
      },
    },
    buttonThumbnail: {
      width: "200px",
      padding: "12px 48px",
      color: "white",
      border: "solid 2px white",
      textAlign: "center",
      margin: '10px',
      opacity: 0,
      zIndex: 1,
      transition: "opacity .35s ease",
      textDecoration: "none",
    },
    tableReplText: {
      marginBottom: "50px",
    },
    tableImage: {
      height: "100%",
    },
    dropZoneWrapper: {
      width: "100%",
      height: "100%",
      position: "absolute",
      background: colors.background.ultraDark,
      // textAlign: 'center',
      margin: 0,
      top: 0,
      left: 0,
      opacity: 0,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageDropZone: {
      alignItems: "center",
      // padding: "-20px",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: colors.background.lighter,
      borderStyle: "dashed",
      width: "100%",
      height: "100%",
      // backgroundColor: "#fafafa",
      color: colors.background.lighter,
      transition: "border .24s ease-in-out",
    },
    singInMediaWrapper: {
      width: "100%",
      marginTop: "35px",
    },
    spanText: {
      marginTop: "20px",
      fontSize: "11px",
      letterSpacing: ".04em",
      lineHeight: "16px",
      color: "#2f2f2f",
    },
    signLink: {
      textDecoration: "none",
      color: "#2f2f2f",
    },
    signLinkAuth: {
      textDecoration: "none",
      color: "#2f2f2f",
      borderImageSource: "#029a80",
      borderWidth: "1px",
      borderImageSlice: 1,
      borderBottom: "1px solid",
    },
    signGradient: {
      height: "6px",
      width: "100%",
      background: "#029a80",
    },
    signWrapper: {
      // width:'80%'
      // padding:'0 500px'
      marginTop: "50px",
      "@media screen and (max-width: 600px)": {
        marginTop: "81px",
        padding: "10px",
      },
    },
    firstTableCell: {
      width: "15%",
    },
    tableCellImageWrapper: {
      border: "1px solid black",
      display: "inline-block",
      height: "60px",
    },
    logoWithBackground: {
      position: "absolute",
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      top: "8px",
      left: "8px",
      padding: '5px',
      borderRadius: '5px',
    },
    logoImage: {
      width: "100px",
    },
    spanWrapper: {
      marginBottom: "15px",
    },
    terms: {
      textAlign: "center",
    },
    signRoute: {
      position: "absolute",
      top: "8px",
      right: "16px",
    },
    signButton: {
      height: "39px",
      background: "#029a80",
      width: "150px",
    },
    filterLi: {
      listStyleType: "none",
      transition: "opacity .24s ease-in-out",
    },
    filteredItem: {
      width: "100%",
      minHeight: "22px",
      textAlign: "left",
      fontSize: "12px",
      marginRight: "12px",
      border: "none",
      cursor: "pointer",
      transition: "opacity .24s ease-in-out",
    },
    filterItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
      transition: ".3s linear",
      "&:hover": {
        "& $chosenItemCheckmark": {
          display: "block",
        },
        "& $filteredItem": {
          // color: "rgba(14,141,84,1)",
          fontWeight: "900",
        },
        "& $chosenNameItemCheckmark": {
          display: "block",
        },
      },
    },
    filterItemChosenWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      // "& $chosenItemCheckmark": {
      //   display: "block",
      //   transition: ".3s linear",
      //   transform: "rotate(180deg)",
      // },
      "& $filteredItem": {
        fontWeight: "900",
      },
      "& $chosenNameItemCheckmark": {
        display: "block",
      },
    },
    treeNavItemIsExpandable: {
      display: "block",
      // color: "#ccc",
      // position: "relative",
      // "& > $treeNavItemTitle::before": {
      //   position: "absolute",
      //   willChange: "transform",
      //   transition: "transform 300ms ease",
      //   fontFamily: "ionicons",
      //   fontSize: "1.1rem",
      //   content: "' \\226B'",
      //   display: "inline-block",
      //   width: "1.6rem",
      //   textAlign: "center",
      // },
    },
    treeNavItem: {},
    isExpandable: {},
    treeNavItemTitle: {
      cursor: "pointer",
      display: "block",
      outline: "0",
      fontSize: "18px",
      "&::-webkit-details-marker": {
        display: "none",
      },
    },
    chosenItemHits: {
      fontSize: "10px",
      color: colors.background.main,
      height: "19px",
      paddingRight: "5px"
    },
    chosenItemCheckWrapper: {
      display: "flex",
      position: "relative",
      marginRight: "15px",
      fill: "green",
    },
    fillPathIcon: {
      fill: colors.success.main,
      transition: ".3s linear",
    },
    chosenNameItemCheckmark: {
      paddingBottom: "10px",
      display: "none",
    },
    filteredItemNameWrapper: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    dropDownCategories: {
      marginLeft: "40px",
      transition: "all 200ms ease",
    },
    mainFilterWrapper: {
      display: "block",
      "& $dropDownCategories": {
        display: "none",
      },
    },
    mainFilterWrapperChosen: {
      display: "block",
      "& $dropDownCategories": {
        display: "block",
      },
    },
    chosenItemCheckmark: {
      // display: "none",
      position: "absolute",
      right: "-15px",
      top: "2px",
      transition: ".3s linear",
      transform: "rotate(90deg)",
    },
    chosenItemCheckmarkOpen: {
      // display: "none",
      position: "absolute",
      top: "4px",
      transition: ".3s linear",
      // transform: "rotate(180deg)",
    },
    categoryCheckbox: {
      position: "absolute",
      cursor: 'pointer',
      top: "1px",
      left: "-18px",
    },
    sizeCheckbox: {
      position: "absolute",
      top: "3px",
      left: "-18px",
    },
    chosenItemCheckmarkDelete: {
      position: "absolute",
      right: "4px",
      bottom: "10px",
      opacity: "0.5",
      cursor: "pointer",
      transition: ".2s linear",
      "&:hover": {
        opacity: 1,
      },
    },
    paletteWrapper: {
      width: "80%",
      height: "30px",
      border: "1px solid " + colors.background.darker,
      display: "flex",
      alignItems: "center",
      "& > *:last-child": {
        marginRight: 0,
      },
      cursor: "pointer",
      transition: "opacity .24s ease-in-out",
      "&:hover": {
        background: "rgba(191,250,193,1)",
      },
    },
    paletteList: {
      listStyleType: "none",
      flexWrap: "wrap",
      display: "flex",
      paddingLeft: "20px",
      margin: 0,
      "& > *": {
        marginRight: "10px",
      },
    },
    paletteItem: {
      width: "20px",
      height: "20px",
      padding: "1px",
      borderRadius: "2px",
      display: "block",
      outline: "none",
      border: "1px solid black",
    },
    tableCell: {
      padding: "5px",
    },
    paletteWrapperSelected: {
      width: "80%",
      height: "30px",
      border: "2px solid rgba(80,200,120,1)",
      transition: "opacity .24s ease-in-out",
      borderRadius: "2px",
      paddingTop: "3px",
      background: "white",
      "-webkit-box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
      "-moz-box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
      "box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
    },
    sizesWrapper: {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    sizeItem: {
      width: "80%",
      height: "30px",
      cursor: "pointer",
      transition: "opacity .24s ease-in-out",
      "&:hover": {
        background: "rgba(191,250,193,1)",
      },
    },
    sizeItemSelected: {
      width: "80%",
      height: "30px",
      border: "2px solid rgba(80,200,120,1)",
      transition: "opacity .24s ease-in-out",
      borderRadius: "2px",
      // paddingTop: '3px',
      background: "white",
      "-webkit-box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
      "-moz-box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
      "box-shadow": "-6px 9px 62px -5px rgba(191,250,193,0.87)",
    },
    inputGallerySearch: {
      width: "100%",
      boxSizing: "border-box",
      border: "none",
      outline: "none",
      fontSize: "16px",
      backgroundColor: "white",
      padding: "11px 0px 8px 12px",
    },
    searchIcon: {
      position: "absolute",
      right: "30px",
      cursor: "pointer",
      marginTop: "8px",
      "@media screen and (max-width: 600px)": {
        right: "10px",
      },
    },
    searchNameWrapper: {
      display: "flex",
    },
    searchItemElements: {
      display: "flex",
      flexWrap: "wrap",
      minHeight: "40px",
    },
    searchItemName: {
      color: colors.dropZone.main,
      paddingTop: "3px",
      paddingLeft: "16px",
      margin: "0",
      borderRadius: "9999px",
      paddingRight: "26px",
      paddingBottom: "3px",
      backgroundColor: colors.background.lighter,
      whiteSpace: "nowrap",
    },
    searchItemNameWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      marginRight: "5px",
      marginBottom: "4px",
    },
    deleteSearchItem: {
      width: "30px",
      height: "30px",
      "&::after": {
        transform: "rotate(-45deg)",
        content: "' '",
        left: "15px",
        height: "33px",
        width: "2px",
        backgroundColor: colors.background.darkest,
      },
      "&::before": {
        transform: "rotate(45deg)",
        content: "' '",
        left: "15px",
        height: "33px",
        width: "2px",
        backgroundColor: colors.background.darkest,
      },
    },
    filterItem: {
      display: "inline-block",
      height: "100%",
      marginBottom: "30px",
      backgroundColor: "white",
      minWidth: "180px",
      maxWidth: "180px"
    },
    filerName: {
      fontWeight: "bold",
      fontSize: "21px",
    },
    categoryIcon: {
      content: `''`,
      width: "6px",
      height: "6px",
      marginTop: "17px",
    },
    categoryNameWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      borderBottom: " 1px solid rgba(0,0,0,.3)",
    },
    modal: {
      display: "none",
      position: "fixed",
      zIndex: 1400,
      padding: '2rem',
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    modalTemplateCreate: {
      display: "none",
      position: "fixed",
      zIndex: 1000,
      paddingTop: "100px",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    gridBoxItem: {
      boxSizing: "border-box",
      margin: "0",
      paddingRight: "20px",
      paddingBottom: "20px",
    },
    gridBoxItemCreate: {
      boxSizing: "border-box",
      margin: "0",
      paddingRight: "20px",
      paddingBottom: "20px",
    },
    imageTemplateSize: {
      display: "flex",
      flexFlow: 'column nowrap',
      alignItems: "center",
      justifyContent: "center",
    },
    imageTemplateSizeInputWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageTemplateInput: {
      width: "100px",
    },
    imageTemplateSizeSeparator: {
      margin: "0 7px",
    },
    imageTemplateRatioWrapper: {
      padding: '7px 7px 0 7px',
    },
    blindWrapper: {
      position: "absolute",
      left: 0,
      width: "100%",
      height: "101%",
      backgroundColor: "#0000006b",
    },
    imageWrapperPopup: {
      margin: 0,
      top: "50%",
      left: "50%",
      position: "absolute",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    galleryImageWrapperPopup: {
      margin: 0,
      top: "50%",
      left: "50%",
      position: "absolute",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "block",
    },
    modalGalleryTemplate: {
      backgroundColor: "white",
      display: "inline-block",
      padding: "0 10px 10px 10px",
      borderRadius: "5px",
      minWidth: "500px",
      position: "relative",
      maxWidth: "fit-content",
      margin: "0 auto",
    },
    modalGalleryTemplateActions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closePopupIcon: {
      color: "black",
      fontSize: "31px",
      cursor: "pointer",
    },
    fullScreenIcon: {
      width: "30px",
      height: "30px",
      marginRight: "10px",
      cursor: "pointer",
    },
    modalGalleryTemplateSize: {
      fontWeight: 500,
      marginRight: "20px",
    },
    modalGalleryTemplateTags: {
      display: "flex",
      marginBottom: "20px",
      flexWrap: "wrap",
      alignItems: "center"
    },
    modalGalleryTemplateHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    modalGalleryTemplateName: {
      fontSize: "16px",
      fontWeight: 500,
    },
    modalGalleryTemplateRow: {
      marginTop: "20px",
    },
    modalGalleryTemplateRowActions: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    modalGalleryTemplateAction: {
      padding: " 2px 7px",
      borderRadius: "3px",
      cursor: "pointer",
      width: "130px",
      color: "white",
      "&:hover": {
        opacity: "0.8",
      },
    },
    modalGalleryFillSizeImage: {
      display: "none",
    },
    modalGalleryTemplateThumbnail: {
      padding: '5px',
      boxShadow: '2px 2px 10px grey',
      maxWidth: '80vw'
    },
    modalGalleryTemplateRowName: {
      fontWeight: 500,
      marginBottom: "7px",
      textAlign: "initial",
    },
    modalImage: {
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    modalGalleryTemplateRowFields: {
      display: "flex",
    },
    modalGalleryTemplateImageDisplay: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    modalGalleryTemplateImageWrapper: {
      maxHeight: "max-content",
      width: '700px',
      display: "flex",
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflowX: 'auto',
      overflowY: 'hidden'
    },
    modalGalleryTemplateImage: {
      maxHeight: "170px",
      maxWidth: "170px",
      margin: '5px'
    },
    sizeImage: {
      marginTop: "30px",
      left: "50%",
      color: "white",
      position: "absolute",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "20px",
      whiteSpace: "nowrap",
    },
    sizeImageTemplate: {
      marginTop: "30px",
      left: "50%",
      color: "white",
      position: "absolute",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "20px",
    },
    modalGeneratedImageWrapper: {
      left: "50%",
      top: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
    },
    modalGeneratedImage: {
      backgroundColor: "white",
      padding: "3px",
      borderRadius: "5px",
    },
    modalGenerateImageName: {
      fontSize: "16px",
      fontWeight: 600,
    },
    modalGenerateImageTime: {
      fontSize: "14px",
      color: colors.background.main,
    },
    modalGenerateTableName: {
      fontSize: "16px",
      fontWeight: 600,
      marginBottom: "5px",
    },
    modalGenerateImageButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: 'center',
      width: '100%',
      padding: '5px'
    },
    modalGenerateImageButton: {
      margin: '0 10px'
    },
    modalGenerateImageSize: {
      textAlign: "center",
      color: colors.background.main,
      fontSize: "16px",
    },
    modalGenerateImage: {
      // padding: "10px",
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "0 auto",
    },
    modalImageWrapperTemplate: {
      margin: "auto",
      boxShadow:
        "16px 1rem 1.1875rem rgba(0,0,0,0.12), -12px -7px 2.125rem rgba(0,0,0,0.24)",
      width: "84%",
      display: "flow-root",
      backgroundColor: colors.background.lightest,
      position: "relative",
      textAlign: "center",
      borderRadius: "4px",
      height: "50%",
    },
    modalGalleryTemplateWrapper: {
      margin: "auto",
      boxShadow:
        "16px 1rem 1.1875rem rgba(0,0,0,0.12), -12px -7px 2.125rem rgba(0,0,0,0.24)",
      display: "flow-root",
      backgroundColor: colors.background.lightest,
      textAlign: "center",
      borderRadius: "4px",
      height: "auto",
    },
    modalImageBlock: {
      width: "80%",
      height: "60%",
      margin: "0 auto",
      display: "inline-block",
    },
    modalImageWrapper: {
      margin: "auto",
      boxShadow:
        "16px 1rem 1.1875rem rgba(0,0,0,0.12), -12px -7px 2.125rem rgba(0,0,0,0.24)",
      // display: "flex",
      display: "flow-root",
      backgroundColor: colors.background.lightest,
      // animation: `$zoom 200ms ${theme.transitions.easing.easeInOut}`,
      textAlign: "center",
      borderRadius: "4px",
      height: "auto",
    },
    modalEmptyTemplateWrapper: {
      margin: "auto",
      boxShadow:
        "16px 1rem 1.1875rem rgba(0,0,0,0.12), -12px -7px 2.125rem rgba(0,0,0,0.24)",
      // display: "flex",
      display: "flow-root",
      backgroundColor: colors.background.ultralight,
      // animation: `$zoom 200ms ${theme.transitions.easing.easeInOut}`,
      textAlign: "center",
      borderRadius: "4px",
      position: "relative",
      height: "auto",
      width: "80%",
    },
    templateCreate: {
      margin: "27px auto",
      border: "1px solid " + colors.background.main,
      maxWidth: "100%",
      backgroundImage: `url(${TemplateBackground})`,
    },
    buttonsSizeItemsWrapper: {
      width: "100%",
      padding: "18px",
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#2f2f2f",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar": {
        backgroundColor: "rgba(66, 103, 178, 0.05)",
        height: "10px",
      },
    },
    buttonSizeWrapper: {
      // width: "100%",
      height: " 265px",
      minWidth: "375px",
      marginRight: "20px",
      marginBottom: "20px",
      display: "inline-table",
      position: "relative",
    },
    inputCustomSizeWrapper: {
      display: "flex",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
    },
    customSizeButton: {
      border: "none",
      cursor: "pointer",
      position: "absolute",
      bottom: "15px",
      right: "15px",
      backgroundColor: colors.success.main,
      color: colors.background.ultralight,
      padding: "3px 7px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: colors.success.light,
      },
    },
    inputSizeWrapper: {},
    inputSizeLabel: {
      fontWeight: 600,
    },
    inputSize: {},
    buttonSizeGoogleWrapper: {
      display: "inline-table",
      position: "relative",
      minWidth: "375px",
      // width: "100%",
      marginRight: "20px",
      marginBottom: "20px",
      height: " 265px",
      border: "3px solid transparent",
      background: "rgba(66, 103, 178, 0.05))",
      borderImage:
        "linear-gradient(90deg, rgba(202,75,58,1) 0%, rgba(208,98,48,1) 25%, rgba(233,191,8,1) 25%, rgba(192,184,31,1) 50%, rgba(104,168,81,1) 50%, rgba(104,168,81,1) 75%, rgba(96,127,244,1) 75%, rgba(96,127,244,1) 100%)",
      borderImageSlice: 1,
    },
    buttonSizeInstagramWrapper: {
      display: "inline-table",
      // width: "100%",
      marginRight: "20px",
      marginBottom: "20px",
      minWidth: "375px",
      height: " 265px",
      border: "3px solid transparent",
      borderImage:
        "linear-gradient(to right, rgba(131,58,180,1), rgba(253,29,29, 1), rgba(252,179,69,1))",
      borderImageSlice: 1,
      background: "rgba(66, 103, 178, 0.05))",
    },
    popularSizeButtonsWrapper: {
      paddingLeft: "5px",
      paddingTop: "10px",
    },
    showMorePopularSizes: {
      position: "absolute",
      right: "132px",
      bottom: "-10px",
      border: "1px solid black",
      backgroundColor: "white",
      fontSize: "12px",
      padding: "0 4px",
      borderRadius: "7px",
      cursor: "pointer",
      fontWeight: 500,
    },
    popularSizeButtonWrapper: {
      position: "relative",
      display: "flex",
      marginBottom: "9px",
      "&:hover $popularSizeButton": {
        backgroundColor: "white",
        color: colors.success.main,
        boxShadow: "0 2px 25px rgba(76, 175, 85, 0.5)",
      },
    },
    popularSizeButton: {
      backgroundColor: colors.background.lighter,
      padding: "0px 20px",
      display: "inline-block",
      borderRadius: "16px",
      cursor: "pointer",
      width: "112px",
      marginRight: "10px",
    },
    popularSizeName: {
      whiteSpace: "nowrap",
      paddingRight: "8px",
    },
    sizesToggleButtonsWrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop: "15px",
    },
    sizesToggleButtonWrapper: {
      position: "relative",
      whiteSpace: "nowrap",
    },
    sizeButtonName: { position: "absolute", fontSize: "16px" },
    labelButtonSwitch: {
      position: "relative",
      display: "inline-block",
      width: "50px",
      height: "23px",
      marginRight: "10px",
      "& $input": {
        opacity: 0,
        width: 0,
        height: 0,
        position: "absolute",
        cursor: "pointer",
      },
    },
    toggleInput: {
      "&:checked ~ $buttonSliderRound": {
        backgroundColor: colors.default.darker,
      },
      "&:focus + $buttonSliderRound": {
        boxShadow: "0 0 1px " + colors.default.darker,
      },
      "&:checked + $buttonSliderRound::before": {
        "-webkit-transform": "translateX(26px)",
        "-ms-transform": "translateX(26px)",
        transform: "translateX(26px)",
      },
    },
    buttonSliderRound: {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "34px",
      backgroundColor: colors.background.light,
      "-webkit-transition": ".4s",
      transition: ".4s",
      "&::before": {
        position: "absolute",
        content: "' '",
        borderRadius: "50%",
        height: "17px",
        width: "17px",
        left: "4px",
        bottom: "3px",
        backgroundColor: "white",
        "-webkit-transition": ".4s",
        transition: ".4s",
      },
    },

    selectedPopularSizeButton: {
      backgroundColor: "white",
      padding: "3px 20px",
      display: "inline-block",
      borderRadius: "16px",
      cursor: "pointer",
      width: "112px",
      marginRight: "10px",
      color: colors.success.main,
      boxShadow: "0 2px 25px rgba(76, 175, 85, 0.5)",
    },
    selectedPopularSizeName: {
      whiteSpace: "nowrap",
      // paddingRight: "8px",

      fontWeight: 500,
      fontSize: "14px",
      // animation: `$zoom 1000ms ${theme.transitions.easing.easeInOut}`,
      textAlign: "center",
      borderRadius: "2px",
    },
    caption: {
      margin: "auto",
      display: "block",
      width: "80%",
      maxWidth: "700px",
      textAlign: "center",
      color: colors.background.light,
      padding: "10px 0",
      height: "150px",
      animation: `$zoom 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes zoom": {
      "0%": {
        transform: "scale(0)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    paginationListWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    paginationCollection: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'flex-start',
      minHeight: '320px',
      marginTop: '16px',
      width: '100%'
    },
    paginationWrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    paginationSizeSelect: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      marginLeft: '25px'
    },
    pagePaginationTfoot: {
      display: "table-footer-group",
    },
    pagePaginationTr: {
      color: "inherit",
      display: "table-row",
      outline: 0,
      verticalAlign: "middle",
    },
    pagePaginationTd: {
      color: " rgba(0, 0, 0, 0.87)",
      overflow: "auto",
      fontSize: " 0.875rem",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    pagePaginationWrapper: {
      minHeight: "52px",
      paddingRight: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pagePaginationSpacer: {
      flex: "1 1 100%",
    },
    pagePaginationRowSelectWrapper: {
      color: "inherit",
      fontSize: "inherit",
      flexShrink: 0,
      marginLeft: "8px",
      marginRight: "32px",
      display: "inline-flex",
      position: "relative",
      alignItems: "center",
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
    },
    pagePaginationRowSelect: {
      // paddingRight: "24px",
      textAlign: "right",
      paddingLeft: "8px",
      textAlignLast: "right",
      cursor: "pointer",
      minWidth: " 16px",
      userSelect: "none",
      borderRadius: 0,
      font: "inherit",
      color: "currentColor",
      width: "100%",
      border: 0,
      height: "1.1876em",
      margin: "0",
      display: "block",
      padding: "6px 0 7px",
      background: "none",
      boxSizing: "content-box",
      letterSpacing: "inherit",
      "-webkit-tap-highlight-color": "transparent",
    },
    pagePaginationRowIcon: {
      top: "calc(50% - 12px)",
      color: "rgba(0, 0, 0, 0.54)",
      right: 0,
      position: "absolute",
      pointerEvents: "none",
      width: "1em",
      height: " 1em",
      display: "inline-block",
      fontSize: "1.5rem",
      transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      flexShrink: 0,
    },
    pagePaginationActionWrapper: {
      flexShrink: 0,
      marginLeft: "20px",
      display: "flex",
    },
    pagePaginationAction: {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "transparent",
      cursor: "pointer",
      border: "none",
      flex: "0 0 auto",
      padding: "12px",
      fontSize: "1.5rem",
      textAlign: "center",
    },
    pagePaginationRowSpan: {
      width: "100%",
      display: "flex",
      alignItems: "inherit",
      justifyContent: "inherit",
      borderRadius: "50%",
      "&:hover": {
        background: colors.pagination.main,
      },
    },
    pagePaginationRowSpanEnd: {
      width: "100%",
      display: "flex",
      alignItems: "inherit",
      justifyContent: "inherit",
      color: colors.pagination.dark,
    },
    pagePaginationActionIcon: {
      width: "1em",
      height: "1em",
      display: "inline-block",
      fontSize: "1.5rem",
      flexShrink: 0,
    },

    pagePaginationRowText: {
      flexShrink: 0,
      color: "inherit",
    },
    close: {
      position: "absolute",
      top: "60px",
      right: "35px",
      color: colors.background.lightest,
      fontSize: "40px",
      fontWeight: "bold",
      transition: "0.3s",
      "&:hover": {
        color: colors.background.light,
        textDecoration: "none",
        cursor: "pointer",
      },
    },

    cardWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "278px",
      maxWidth: "230px",
    },
    imageCardWrapper: {
      display: "flex",
      position: "relative",
    },
    card: {
      height: "100%",
      boxShadow:
        "0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05)",
      overflow: "hidden",
      maxWidth: "250px",
      borderRadius: "4px",
      position: "relative",
      transition: "0.5 ease",
      "&:hover": {
        "& $cardTop": {
          opacity: 1,
        },
      },
    },
    cardCategories: {
      paddingRight: "5px",
      paddingLeft: "5px",
      cursor: "pointer",
      minHeight: "30px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    cardText: {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    cardImage: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    cardTemplateImage: {
      maxWidth: "100%",
      maxHeight: "100%",
      cursor: "pointer",
      margin: 0,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    cardImageTemplate: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      position: "absolute",
      maxHeight: "117px",
      borderBottom: "1px solid black",
      objectFit: "contain",
    },
    cardCategoryText: {
      fontSize: "10px",
      color: colors.dropZone.dark,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "3px",
      paddingBottom: "3px",
      marginRight: ".5rem",
      display: "inline-block",
      borderRadius: "9999px",
      backgroundColor: colors.background.lighter,
    },
    templateCardName: {
      maxHeight: "56px",
      fontSize: "12px",
      position: "relative",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "215px",
    },
    showMoreTemplateFileName: {
      position: "absolute",
      width: "20px",
      height: "20px",
      top: "-1px",
      right: "-1px",
      cursor: "pointer",
      content: "' '",
    },
    templateCardButton: {
      display: "flex",
      justifyContent: "space-between",
    },
    templateCardSize: {
      fontSize: "10px",
    },
    templateFileNameSpan: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "400px",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      width: "90%",
      position: "absolute",
      bottom: 0,
    },
    templateCardLink: {
      textDecoration: "none",
      margin: "10px 0",
      fontSize: "10px",
    },
    cardBlock: {
      width: "100%",
      height: "100%",
      zIndex: 999,
      position: "absolute",
      top: 0,
      background: "rgba(222,222,222,0.58)",
    },
    popupBlock: {
      width: "100%",
      height: "calc(100% + 65px)",
      zIndex: 999,
      position: "absolute",
      top: 0,
      background: "rgba(222,222,222,0.58)",
    },
    popupBlockTemplate: {
      width: "100%",
      height: "100%",
      zIndex: 999,
      position: "absolute",
      top: 0,
      right: 0,
      background: "rgba(222,222,222,0.58)",
    },
    cardCreate: {
      height: "100%",
      width: "240px",
      borderRadius: "4px",
      position: "relative",
      margin: "6px",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "center",
      border: "2px dashed " + colors.success.main,
      backgroundColor: colors.background.lighter,
      transition: "0.5 ease",
      "&:hover": {
        "& $cardTop": {
          opacity: 1,
        },
      },
    },
    templateCardCreate: {
      textDecoration: "none",
      margin: "10px 0",
      fontSize: "14px",
      color: colors.success.main,
      textTransform: "uppercase",
      border: "1px solid " + colors.success.main,
      paddingTop: "3px",
      paddingBottom: "3px",
      paddingLeft: "7px",
      paddingRight: "7px",
      transition: "all 0.3s ease-in-out",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: colors.success.light,
        color: "white",
      },
    },
    templateCardCreateButton: {
      color: colors.success.main,
      border: "1px solid " + colors.success.main,
      backgroundColor: "white",
      cursor: "pointer",
      padding: "10px",
      margin: "5px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: colors.success.light,
        color: "white",
      },
    },
    templateCreateInPopupButton: {
      textDecoration: "none",
      margin: "0",
      position: "absolute",
      width: "100%",
      fontSize: "17px",
      color: "white",
      textTransform: "uppercase",
      fontWeight: 500,
      backgroundColor: colors.success.main,
      cursor: "pointer",
      padding: "20px",
      transition: "all 0.3s ease-in-out",
      // borderRadius: "4px",
      "&:hover": {
        backgroundColor: colors.success.light,
        color: "white",
      },
    },
    buttonSizeText: {
      whiteSpace: "nowrap",
    },
    cardTop: {
      position: "absolute",
      padding: "16px",
      width: "100%",
      opacity: 0,
      top: 0,
      zIndex: 999,
    },
    cardLike: {
      width: "18px",
      "&:hover": {
        "& $cardLikeLine": {
          fill: "red",
        },
      },
      cursor: "pointer",
    },
    cardImageWrapper: {
      maxWidth: "100%",
      maxHeight: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: "140px",
      position: "relative",
    },
    cardButton: {
      // marginRight: '10px',
      // '&:last-child':{
      //     marginRight: 0
      // }
      width: "68px",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "all 0.1s ease-in-out",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "12px",
      paddingRight: "12px",
      background: "white",
      fontWeight: "bold",
      color: "hsl(214deg 79% 65%)",
      border: "none",
      "&:nth-child(1)": {
        "&:hover": {
          color: "hsl(43deg 100% 66%)",
        },
      },
      "&:nth-child(2)": {
        "&:hover": {
          color: "hsl(165deg 58% 55%)",
        },
      },
      "&:nth-child(3)": {
        "&:hover": {
          color: "hsl(354deg 81% 63%)",
        },
      },
    },
    cardButtonWrapper: {
      display: "flex",
    },
    cardSvgButtonIcon: {
      width: "16px",
    },
    cardButtonSpan: {},
    preloaderWrapper: {
      display: "flex",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      zIndex: 1000,
      transform: "translate(-50%, -50%)",
      position: "absolute",
    },
    preloaderWrapperTemplate: {
      display: "flex",
      position: "relative",
      marginBottom: "100px",
    },
    preloader: {
      margin: 0,
      opacity: 1,
      width: "80px",
      height: "80px",
      border: "2px solid " + colors.background.lighter,
      borderTop: "3px solid " + colors.preloader.main,
      borderRadius: "100%",
      animation: `$spin 1s infinite linear`,
    },
    cardLikeLine: {
      fill: colors.background.ultraDark,
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
    /**
     * Make image page
     */
    makeImagePageWrapper: {
      // width: "100%",
      // height: "100%",
      textAlign: "center",
    },

    makeImageLastTemplatesWrapper: {
      border: "2px inset #2f2f2f",
      height: "146px",
      display: "flex",
      padding: "20px 30px 10px 30px",
      overflowX: "auto",
      overflowY: "hidden",
      marginBottom: "30px",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        height: "10px",
        transition: "0.2s ease all",
        backgroundColor: colors.background.lighter,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.scrollbar.main,
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: colors.scrollbar.light,
        borderRadius: "10px",
      },
    },
    makeImageLastTemplateWrapper: {
      padding: "3px",
      cursor: "pointer",
      marginRight: "10px",
      display: "inline-block",
      minWidth: "180px",
      maxWidth: "180px",
      height: "100%",
      position: "relative",
      "&:hover": {
        "& $makeImageLastTemplateDeleteButtonWrapper": {
          display: "block",
        },
        border: "3px solid " + colors.success.lighter,
        borderRadius: "8px",
      },
    },
    makeImageLastTemplateWrapperActive: {
      padding: "3px",
      minWidth: "180px",
      border: "3px solid " + colors.success.dark,
      marginRight: "10px",
      borderRadius: "8px",
      maxWidth: "180px",
      display: "inline-block",
      height: "100%",
      position: "relative",
      "&:hover": {
        "& $makeImageLastTemplateDeleteButtonWrapper": {
          display: "block",
        },
      },
    },
    makeImageLastTemplateWrapperActiveText: {
      padding: "3px",
      minWidth: "180px",
      border: "3px dashed " + colors.success.dark,
      marginRight: "10px",
      borderRadius: "3px",
      maxWidth: "180px",
      display: "inline-block",
      backgroundColor: colors.success.lightest,
      height: "100%",
      position: "relative",
    },
    makeImageLastChooseText: {
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      fontSize: "16px",
      color: colors.success.dark,
    },
    makeImageLastTemplateImage: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    makeImageLastTemplateDeleteButtonWrapper: {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "8px",
      cursor: 'pointer',
      right: "13px",
    },
    makeImageLastTemplateDeleteButton: {
      width: "19px",
      height: "19px",
      cursor: "pointer",
      display: "none",
      backgroundColor: colors.background.lightest,
      border: "1px solid " + colors.danger.main,
      borderRadius: "50%",
      "&::after": {
        content: "' '",
        width: "2px",
        height: "17px",
        background: colors.danger.main,
        right: "9px",
        top: "1px",
        transform: "rotate(-45deg)",
        position: "absolute",
      },
      "&::before": {
        content: "' '",
        width: "2px",
        height: "17px",
        background: colors.danger.main,
        left: "9px",
        top: "1px",
        transform: "rotate(45deg)",
        position: "absolute",
      },
    },
    makeImagePickButtonsWrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      marginBottom: "30px",
    },
    makeImagePickButton: {
      padding: "20px 40px",
      backgroundColor: colors.success.lightest,
      border: "3px dashed " + colors.success.main,
      color: colors.success.darker,
      borderRadius: "2px",
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors.success.lightest,
      },
    },
    makeImageArrow: {
      position: "relative",
      width: "185px",
      height: "36px",
      background: colors.arrow.main,
      margin: "0 auto",
      marginBottom: "40px",
      "&::after": {
        content: "' '",
        position: "absolute",
        borderWidth: "160px 0 160px 33px",
        borderStyle: "solid",
        borderColor: "transparent " + colors.arrow.main,
        bottom: "-51px",
        transform: "rotate(90deg)",
        top: "-110px",
        right: "77px",
      },
    },
    makeImageButtonWrapper: {
      display: "inline-block",
      background: colors.default.light,
      marginTop: "7px",
      padding: "1px 12px",
      borderRadius: "7px",
      cursor: "pointer",
    },
    makeImageButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalImageTemplateWrapper: {
      margin: "0 auto",
      marginBottom: "15px",
    },
    makeImageArrowText: {
      paddingTop: "10px",
    },
    popupExtensionWrapper: {
      margin: "auto",
      width: "fit-content",
      maxWidth: '50%',
      maxHeight: '55%',
      overflowY: 'auto',
      backgroundColor: colors.background.lighter,
      position: "relative",
      textAlign: "center",
      borderRadius: "2px",
    },
    warningExtensionButton: {
      color: 'orange',
      cursor: 'pointer',
      padding: '5px',
      marginLeft: 'auto',
      marginRight: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    makeImagePlaceholdersWrapper: {
      position: "relative",
      marginBottom: "30px",
    },
    makeImagePlaceholdersButtonsWrapper: {
      display: "flex",
    },
    makeImagePlaceholdersButtonActive: {
      padding: "8px",
      marginLeft: "40px",
      border: "2px solid black",
      borderRadius: '8px 8px 0 0',
      borderBottom: "none",
      backgroundColor: colors.default.darkest,
      color: "white",
    },
    makeImagePlaceholdersButton: {
      padding: "8px",
      marginLeft: "40px",
      border: "1px solid " + colors.default.lighter,
      borderRadius: '8px 8px 0 0',
      borderBottom: "none",
      backgroundColor: colors.default.lightest,
      cursor: 'pointer'
    },
    makeImagePlaceholdersValuesWrapper: {
      width: "100%",
      minHeight: "70px",
      border: "2px solid " + colors.default.lighter,
      borderRadius: '8px',
      backgroundColor: colors.default.lightest,
    },
    makeImagePlaceholdersFieldsWrapper: {
      "&:   last-child": {
        marginBottom: "30px",
      },
    },
    makeImagePlaceholderFieldWrapper: {
      padding: '5px',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: "left",
      width: '100%',
      "&:hover": {
        backgroundColor: colors.background.catskillWhite,
      },
    },
    makeImagePlaceholderFieldInputWrapper: {
      width: "50%",
      position: "absolute",
      left: "259px",
    },
    makeImagePlaceholderFieldInput: {
      width: "100%",
    },
    makeImagePickImageButton: {
      border: " 1px solid black",
      height: "70px",
      width: "125px",
      cursor: "pointer",
      marginRight: "30px",
      backgroundColor: "white",
      borderRadius: "2px",
    },
    makeImageChosenImage: {
      maxWidth: '70px',
      cursor: "pointer",
    },
    /** Images Popup */
    popupImageWrapper: {
      margin: "auto",
      width: "71%",
      backgroundColor: '#ffffff',
      position: "relative",
      textAlign: "center",
      borderRadius: "2px",
      padding: '10px'
    },
    popupImageDownload: {
      display: "flex",
      padding: "12px 7px",
      alignItems: "center",
    },
    popupImageDownloadInput: {
      margin: "0 20px",
      width: "50%",
    },
    popupImageDownloadButton: {
      backgroundColor: colors.default.dark,
      color: "white",
      padding: "3px 14px",
      borderRadius: "12px",
      cursor: "pointer",
      margin: '5px'
    },
    downloadExtensionWrapper: {
      padding: '10px',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-around',
      alignItems: 'center'
    },
    extensionSettings: {
      display: 'grid',
      gridTemplateColumns: '30% 70%',
      width: '100%'
    },
    tabsListWrapper: {
      padding: '10px',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    chooseImageButton: {
      border: "1px solid " + colors.success.main,
      color: colors.success.main,
      padding: "4px 5px",
      borderRadius: "4px",
      textTransform: "uppercase",
      width: "100%",
      textAlign: "center",
      cursor: "pointer",
      transition: "200ms all linear",
      "&:hover": {
        backgroundColor: colors.success.light,
        color: "white",
      },
    },
    imageTemplateWrapper: {
      display: "inline-block",
      position: "relative",
      overflow: "hidden",
    },
    /** Images Popup */
    /** Render image */
    renderImageWrapper: {
      position: "relative",
      "&:hover": {
        "& $imageGenerateWrapper": {
          display: "block",
        },
      },
    },
    imageGenerateWrapper: {
      zIndex: 52,
      position: 'absolute',
      backgroundColor: 'gray',
      opacity: 0.5,
      width: '100%',
      height: '100%',
      left: 0,
      top: 0
    },
    imageGenerateIcon: {
      position: "absolute",
      width: "120px",
      height: "30%",
      fill: "white",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    /** Render image */
    myDocumentsHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    myDocumentsHeaderName: {
      fontSize: "22px",
      fontWeight: 600,
    },
    myDocumentsTitle: {
      textAlign: "center",
      fontWeight: 600,
    },
    recentTemplatesWrapper: {
      overflow: 'hidden'
    },
    /** Generated Images */
    makeImageLastGenerateImagesWrapper: {
      border: "1px inset black",
      height: "190px",
      display: "flex",
      padding: "20px 30px 10px 30px",
      overflowX: "auto",
      overflowY: "hidden",
      marginBottom: "30px",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        height: "10px",
        transition: "0.2s ease all",
        backgroundColor: colors.background.ultralight,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.scrollbar.main,
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: colors.scrollbar.light,
        borderRadius: "10px",
      },
    },
    makeImageLastGenerateImageWrapper: {
      padding: "3px",
      marginRight: "25px",
      display: "inline-block",
      minWidth: "180px",
      maxWidth: "180px",
      height: "112px",
      position: "relative",
      border: "1px solid " + colors.success.lighter,
      borderRadius: "8px",
    },
    makeImageLastGenerateImageWrapperActive: {
      padding: "3px",
      minWidth: "175px",
      maxWidth: "175px",
      border: "3px solid " + colors.success.lighter,
      marginRight: "10px",
      borderRadius: "3px",
      display: "inline-block",
      height: "112px",
      position: "relative",
    },
    makeImageLastGenerateImage: {
      height: "100%",
      width: "100%",
      marginBottom: "4px",
      objectFit: "contain",
    },
    makeImageLastGenerateImageDeleteButtonWrapper: {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "-12px",
      right: "-8px",
      cursor: 'pointer'
    },
    makeImageLastGenerateImageDeleteButton: {
      width: "19px",
      height: "19px",
      cursor: "pointer",
      backgroundColor: colors.background.lightest,
      border: "1px solid " + theme.palette.error.dark,
      borderRadius: "50%",
      "&::after": {
        content: "' '",
        width: "2px",
        height: "17px",
        background: theme.palette.error.dark,
        right: "9px",
        top: "1px",
        transform: "rotate(-45deg)",
        position: "absolute",
      },
      "&::before": {
        content: "' '",
        width: "2px",
        height: "17px",
        background: theme.palette.error.dark,
        left: "9px",
        top: "1px",
        transform: "rotate(45deg)",
        position: "absolute",
      },
    },
    makeImageLastGenerateImageActions: {
      display: "flex",
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    makeImageIconButton: {
      padding: '6px',
      margin: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    makeImageLastGenerateImageIcon: {
      marginRight: "12px",
      cursor: "pointer",
    },
    myDocumentsActionsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    myDocumentsAction: {
      color: colors.default.main,
      textDecoration: "underline",
      marginRight: "25px",
      cursor: "pointer",
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        color: "black",
      },
    },
    /**
     * Make image page
     */
  })
);

export default useStyles;
