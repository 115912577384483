
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Header } from 'components';
import { useCombinedRef } from 'hooks';
import useStyles from 'styles';
import classnames from 'classnames';

type Popup = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	open?: boolean;
	lock?: boolean;
	onClose?: () => void;
	title?: string;
};

const Popup = React.forwardRef<HTMLDivElement, Popup>(({
	children, open, lock, className, style, onClose, title
}, modalRef) => {
	const [visible, setVisible] = useState<boolean>(open ?? false);
	const [isOnPopup, setIsOnPopup] = useState<boolean>(false);

	const innerRef = useRef(null);
	const ref = useCombinedRef<HTMLDivElement>(modalRef, innerRef);

	useEffect(() => {
		if(ref.current) {
			ref.current.style.display = visible ? 'flex' : 'none';

			document.body.style.overflowY = visible ? 'hidden' : 'scroll';
			document.body.style.overflowX = visible ? 'hidden' : 'auto';
		}

		if(!visible) {
			onClose && onClose();
		}
	}, [visible]);

	useEffect(() => {
		// eventually would be nice to use useImperativeHandle to add open/close functions
		// but typescript is a pain, sooo
		if(open !== undefined) {
			setVisible(open);
		}
	}, [open]);

	const closePopup = (force?: boolean) => {
		if(force) {
			setVisible(false);
		} else {
			if(!isOnPopup) {
				setVisible(false);
			}
		}
	}

	const classes = useStyles();
	return (
    <div
      onClick={(e) => !lock && closePopup()}
      ref={ref}
      className={classes.pageModal}
    >
      <div className={classnames(classes.pageModalBody, className)} style={style}
				onMouseEnter={() => setIsOnPopup(true)} onMouseLeave={() => setIsOnPopup(false)}>
					{title &&
						<Header title={title} className={classes.pageModalTitle} close={!lock ? closePopup : undefined} />
					}
				{children}
			</div>
		</div>
	);
});

export default Popup;