
import React, { useState, ReactNode } from 'react';
import classnames from 'classnames';

import useStyles from 'styles';

const Tab: any = (props:
  { children: ReactNode, name: string}) => {
    return props.children;
};

type PageTabs = {
	children: typeof Tab[];
	tab?: string;
	style?: React.CSSProperties;
};

const PageTabs = ({
	children, style, tab
}: PageTabs) => {
	const classes = useStyles();
	const [activeTab, setActiveTab] = useState<string>(tab ?? children[0].props.name);

	return (
		<div className={classes.tabWrapper} style={style}>
			<div className={classes.tabSelectWrapper}>
				{children.map(child => (
					<div className={classnames(classes.tab, {[classes.tabActive]: activeTab === child.props.name})}
						onClick={() => setActiveTab(child.props.name)}>
						<p className={classes.tabTitle}>{child.props.name}</p>
					</div>
				))}
			</div>

			<div className={classes.activeTabWrapper}>
				{children.find(child => activeTab === child.props.name)}
			</div>
		</div>
	);
}

PageTabs.Tab = Tab;
export default PageTabs;