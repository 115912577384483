import React, {useEffect} from 'react';
import useStyles from 'styles';
import { Container, Grid } from '@material-ui/core';
import RedirectButton from '../RedirectButton';
import SignInWindow from './SignInWindow';
import Logo from 'components/Logo';
import {useParams} from "react-router-dom";
import {useQueryParam, StringParam, BooleanParam } from "use-query-params";
import {ApiKeyRequestActionCreator} from "../../../state/apikeyrequest/ApiKeyRequestActionCreator";
import { DqoLoginActionCreator } from 'state/dqologin/DqoLoginActionCreator';
import authentication from "services/authentication";

type SignIn = {
  changeUser: (arg0: any) => void;
};

type RequestApiKeyParams = {
    apiKeyRequest?: string;
}

const SignIn = ({ changeUser }: SignIn) => {
  const { apiKeyRequest } = useParams<RequestApiKeyParams>();
  const [ tgt, setTgt] = useQueryParam("tgt", StringParam);
  const [ accountName, setAccountName] = useQueryParam("account", StringParam);
  const [ returnUrl, setReturnUrl] = useQueryParam("returnUrl", StringParam);
  const [ logout, setLogout] = useQueryParam("logout", BooleanParam);

  useEffect(() => {
    if (logout && logout === true) {
      authentication.signOut();
    }
  }, [logout]);

  useEffect(() => {
      if (apiKeyRequest !== undefined) {
          ApiKeyRequestActionCreator.requestApiKey(apiKeyRequest);
          if (tgt !== undefined && returnUrl !== undefined) {
            DqoLoginActionCreator.wrongTenantClearState();
          }
      }
  }, [apiKeyRequest]);

  useEffect(() => {
      if (apiKeyRequest === undefined && tgt !== undefined && returnUrl !== undefined) {
        DqoLoginActionCreator.loginDqoUser(tgt, returnUrl, accountName);
      }
  }, [tgt, returnUrl, accountName]);
    
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="sm">
      <Logo background />
      {!tgt && 
        <div className={classes.signRoute}>
          <RedirectButton text="Sign Up" link="/registration" />
        </div>
      }
      <Grid container component="main" className={classes.root}>
        <SignInWindow changeUser={changeUser} tenantAccountName={accountName} />
      </Grid>
    </Container>
  );
};
export default SignIn;
