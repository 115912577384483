import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from 'styles';
import { Link } from 'react-router-dom';

const RedirectButton: React.FC<any> = ({ text, link }) => {
  const classes = useStyles();

  return (
    <div>
      <Link className={classes.signLink} to={link}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.signButton}
        >
          {text}
        </Button>
      </Link>
    </div>
  );
};

export default RedirectButton;
