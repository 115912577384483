import useStyles from 'styles';
import React, {useState} from 'react';
import styles from './styles.module.css';

const Tab: any = (props:
  { children?: JSX.Element | JSX.Element[], name: string, displayText: string | JSX.Element, onActive?: () => void,
    getClass?: (active: boolean) => string, fullscreen?: boolean, style?: React.CSSProperties }) => {
    return props.children;
};

type TabDisplay = {
  defaultTab: string;
  children: any[];
  style?: any;
  className?: string;
};

const TabDisplay = ({
  defaultTab,
  children,
  style,
  className
}: TabDisplay) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(defaultTab);

  const getActiveClass = (tab: string) => {
    if(tab === activeTab) return classes.makeImagePlaceholdersButtonActive;
    return classes.makeImagePlaceholdersButton;
  }

  const getActiveTab = () => {
    return tabs.find(item => item.props.name === activeTab);
  }

  const tabs = children.filter((child) => child.type === Tab);

  return (
    <div className={`${classes.makeImagePlaceholdersWrapper} ${className} ${getActiveTab()?.props.fullscreen ? styles.fullscreen : styles.tabWrapper}`} style={style}>
      <div className={classes.makeImagePlaceholdersButtonsWrapper}>
        {tabs?.map(tab => (
          <div
            className={tab.props.getClass ? tab.props.getClass(tab.props.name === activeTab) : getActiveClass(tab.props.name)}
            style={tab.props.style}
            onClick={() => tab.props.onActive ? tab.props.onActive() : setActiveTab(tab.props.name)}
          >
            {tab.props.displayText}
          </div>
        ))}
      </div>

      <div className={classes.makeImagePlaceholdersValuesWrapper + " stepFillValues"}>
        {tabs.find((tab) => tab.props.name === activeTab)}
      </div>
    </div>
  )
}

TabDisplay.Tab = Tab;
export default TabDisplay;