import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

import EmptyState from '../EmptyState';

import { ReactComponent as ErrorIllustration } from 'illustrations/error.svg';

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static propTypes = {
    // Properties
    children: PropTypes.array.isRequired,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      // works?
      scope.setExtras(errorInfo);

      const eventId = Sentry.captureException(error);

      this.setState({
        eventId: eventId,
      });
    });
  }

  render() {
    // Properties
    const { children } = this.props;

    const { hasError } = this.state as any;

    if (hasError) {
      return (
        <EmptyState
          image={<ErrorIllustration />}
          title="Something went wrong"
          description="The app failed to load"
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
