import camelCase from "camelcase";

import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey as gray,
  blueGrey as blueGray,
} from "@material-ui/core/colors";

export interface NamedColor {
  id: string;
  name: string;
  import: { [key: string]: string };
}

export const colors = {
  red: {
    id: "red",
    name: "Red",
    import: red,
  } as NamedColor,

  pink: {
    id: "pink",
    name: "Pink",
    import: pink,
  } as NamedColor,

  purple: {
    id: "purple",
    name: "Purple",
    import: purple,
  } as NamedColor,

  deepPurple: {
    id: "deep-purple",
    name: "Deep Purple",
    import: deepPurple,
  } as NamedColor,

  indigo: {
    id: "indigo",
    name: "Indigo",
    import: indigo,
  } as NamedColor,

  blue: {
    id: "blue",
    name: "Blue",
    import: blue,
  } as NamedColor,

  lightBlue: {
    id: "light-blue",
    name: "Light Blue",
    import: lightBlue,
  } as NamedColor,

  cyan: {
    id: "cyan",
    name: "Cyan",
    import: cyan,
  } as NamedColor,

  teal: {
    id: "teal",
    name: "Teal",
    import: teal,
  } as NamedColor,

  green: {
    id: "green",
    name: "Green",
    import: green,
  } as NamedColor,

  lightGreen: {
    id: "light-green",
    name: "Light Green",
    import: lightGreen,
  } as NamedColor,

  lime: {
    id: "lime",
    name: "Lime",
    import: lime,
  } as NamedColor,

  yellow: {
    id: "yellow",
    name: "Yellow",
    import: yellow,
  } as NamedColor,

  amber: {
    id: "amber",
    name: "Amber",
    import: amber,
  } as NamedColor,

  orange: {
    id: "orange",
    name: "Orange",
    import: orange,
  } as NamedColor,

  deepOrange: {
    id: "deep-orange",
    name: "Deep Orange",
    import: deepOrange,
  } as NamedColor,

  brown: {
    id: "brown",
    name: "Brown",
    import: brown,
  } as NamedColor,

  gray: {
    id: "gray",
    name: "Gray",
    import: gray,
  } as NamedColor,

  blueGray: {
    id: "blue-gray",
    name: "Blue Gray",
    import: blueGray,
  } as NamedColor,
};

const getColor = (colorId: string | undefined): NamedColor | null => {
  if (!colorId) {
    return null;
  }

  colorId = camelCase(colorId);

  return (colors as any)[colorId];
};

const defaultPrimaryColor = getColor(process.env.REACT_APP_THEME_PRIMARY_COLOR);
const defaultSecondaryColor = getColor(
  process.env.REACT_APP_THEME_SECONDARY_COLOR
);
const defaultDark = process.env.REACT_APP_THEME_DARK === "true";

const defaultTheme = createMuiTheme({
  palette: {
    primary: defaultPrimaryColor?.import,
    secondary: defaultSecondaryColor?.import,
    type: defaultDark ? "dark" : "light",
    green: {
      500: "#10B981",
      600: '#00BB9E',
    },
    teal: {
      500: "#029A80",
    }
  },

  typography: {
    h3: {
      textTransform: "uppercase",
      fontSize: "1.1rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      lineHeight: "1.167",
      letterSpacing: "0em",
    },
    h2: {
      fontSize: "1.1rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "0em",
    },
    h1: {
      fontSize: "1.25rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
    },
  },

  overrides: {
    MuiCard: {
      root: {
        minHeight: "320px",
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        overflow: "hidden",
        margin: "6px",
      },
    },
    MuiCardContent: {
      root: {
        padding: "8px",
        maxWidth: "240px",
        minWidth: "240px",
        display: 'flex',
        flexFlow: 'column nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%',
        width: '100%'
      },
    },
    MuiButtonBase: {
      root: {
        flexGrow: 2
      }
    },
    MuiBox: {
      root: {
        maxHeight: "unset",
      }
    },
    MuiCardActions: {
      root: {
        padding: "2px",
      },
    },
    MuiCardMedia: {
      root: {
        height: "200px",
        maxWidth: "240px",
      },
      media: {
        width: "auto",
      },
      img: {
        objectFit: "contain",
        margin: "auto",
      },
    },
    MuiChip: {
      clickable: {
        margin: '1px 2px',
        flexGrow: 0,
        flexShrink: 1
      },
      labelSmall: {
        paddingLeft: '4px',
        paddingRight: '4px',
        fontSize: '0.75rem',
      }
    },
    MuiTypography: {
      root: {
        textOverflow: 'ellipsis'
      }
    },
    MuiGrid: {
      'grid-xs-5': {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'green'
        },
        '&$active': {
          color: '#229099'
        }
      }
    },
    MuiStepLabel: {
      label: {
        fontFamily: 'Lato, sans-serif'
      }
    }
  },

  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
  dark: defaultDark,
} as ThemeOptions);

export interface Appearance {
  colors: { [key: string]: NamedColor };
  defaultPrimaryColor: NamedColor | null;
  defaultSecondaryColor: NamedColor | null;
  defaultDark: boolean;
  defaultTheme: Theme;
  isDefaultTheme(theme: Theme): boolean;
  createTheme(theme: Theme): Theme | null;
  changeTheme(theme: Theme): Promise<void>;
  changePrimaryColor(primaryColorId: string): Promise<void>;
  changeSecondaryColor(secondaryColorId: string): Promise<void>;
  changeDark(dark: boolean): Promise<void>;
  changeSyncAppearance(syncAppearance: boolean): Promise<void>;
  resetTheme(): Promise<void>;
}

const appearance: Appearance = {} as Appearance;

appearance.colors = colors;

appearance.defaultPrimaryColor = defaultPrimaryColor;
appearance.defaultSecondaryColor = defaultSecondaryColor;
appearance.defaultDark = defaultDark;

appearance.defaultTheme = defaultTheme;

appearance.isDefaultTheme = (theme: Theme): boolean => {
  if (!theme) {
    return false;
  }

  if (
    ((theme as any).primaryColor as NamedColor).id ===
      defaultPrimaryColor?.id &&
    ((theme as any).secondaryColor as NamedColor).id ===
      defaultSecondaryColor?.id &&
    (theme as any).dark === defaultDark
  ) {
    return true;
  }

  return false;
};

appearance.createTheme = (theme) => {
  if (!theme) {
    return null;
  }

  let primaryColorId = ((theme as any).primaryColor as NamedColor)?.id;
  let secondaryColorId = ((theme as any).secondaryColor as NamedColor)?.id;
  let dark = (theme as any).dark;

  if (!primaryColorId || !secondaryColorId) {
    return null;
  }

  let primaryColor = getColor(primaryColorId);
  let secondaryColor = getColor(secondaryColorId);

  if (!primaryColor || !secondaryColor) {
    return null;
  }

  theme = createMuiTheme({
    palette: {
      primary: primaryColor.import,
      secondary: secondaryColor.import,
      type: dark ? "dark" : "light",
    },
  });

  return theme;
};


export default appearance;
