import React from "react";

import { Hidden } from "@material-ui/core";

import AboutDialog from "../AboutDialog";

import SignUpDialog from "../SignUpDialog";
import SignInDialog from "../SignInDialog";
import SettingsDialog from "../SettingsDialog";
import DeleteAccountDialog from "../DeleteAccountDialog";
import AlertDialog from "../AlertDialog";
import { Theme } from "@material-ui/core/styles";

type DialogHost = {
  performingAction: boolean;
  theme: Theme;
  user?: any;
  userData?: any;
  dialogs: any;
};

const DialogHost = ({
  performingAction,
  theme,
  user,
  userData,
  dialogs,
}: DialogHost) => {
  const aboutDialog = dialogs.aboutDialog;
  const signUpDialog = dialogs.signUpDialog;
  const signInDialog = dialogs.signInDialog;
  const settingsDialog = dialogs.settingsDialog;
  const deleteAccountDialog = dialogs.deleteAccountDialog;
  const signOutDialog = dialogs.signOutDialog;

  return (
    <>
      <AboutDialog
        dialogProps={aboutDialog.dialogProps}
        performingAction={performingAction}
        theme={theme}
        user={user}
        userData={userData}
        {...aboutDialog.props}
      />

      {user && (
        <>
          <AlertDialog
            prompt='Are you sure you want to sign out?'
            acceptText='Sign out'
            desc={signOutDialog.props.contentText}
            onAccept={signOutDialog.dialogProps.action}
            forceOpen={signOutDialog.dialogProps.open}
          />
        </>
      )}

      <Hidden xsDown>
        {user && (
          <>
            <DeleteAccountDialog
              dialogProps={deleteAccountDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...deleteAccountDialog.props}
            />
          </>
        )}

        {!user && (
          <>
            <SignUpDialog
              dialogProps={signUpDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...signUpDialog.props}
            />

            <SignInDialog
              dialogProps={signInDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...signInDialog.props}
            />
          </>
        )}
      </Hidden>

      <Hidden smDown>
        {user && (
          <>
            <SettingsDialog
              dialogProps={settingsDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...settingsDialog.props}
            />
          </>
        )}
      </Hidden>

      <Hidden smUp>
        {user && (
          <>
            <DeleteAccountDialog
              dialogProps={{
                fullScreen: true,
                ...deleteAccountDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...deleteAccountDialog.props}
            />
          </>
        )}

        {!user && (
          <>
            <SignUpDialog
              dialogProps={{
                fullScreen: true,

                ...signUpDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...signUpDialog.props}
            />

            <SignInDialog
              dialogProps={{
                fullScreen: true,
                ...signInDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...signInDialog.props}
            />
          </>
        )}
      </Hidden>

      <Hidden mdUp>
        {user && (
          <>
            <SettingsDialog
              dialogProps={{
                fullScreen: true,

                ...settingsDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              {...settingsDialog.props}
            />
          </>
        )}
      </Hidden>
    </>
  );
};

export default DialogHost;
