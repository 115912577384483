import React, { useEffect, useState } from "react";
import { EmptyState, Preloader } from "components";
import { DSCCRequestClient } from "../../../services/apiClient";
import { AxiosResponse } from "axios";

const style = {
  iframeWrapper: {
    height: "calc(100vh - 150px)",
  },
  iframe: {
    width: "1600px",
    height: "1800px",
    border: "0px",
  },
};

const DashboardDetails = () => {
  const [refreshingToken, setRefreshingToken] = useState(true);
  const [dsccRefreshToken, setDsccRefreshToken] = useState<string>();

  useEffect(() => {
    if (!dsccRefreshToken) {
      DSCCRequestClient.issueDSCCRefreshToken().then(
        (response: AxiosResponse<string>) => {
          setDsccRefreshToken(response.data);
          setRefreshingToken(false);
        },
        (error) => {}
      );
    }
  }, [dsccRefreshToken, refreshingToken]);

  return (
    <>
      {dsccRefreshToken ? (
        <iframe
          title="header-iframe"
          style={style.iframe}
          src={`https://datastudio.google.com/embed/reporting/00ae6cbe-7a1d-48d7-b221-4d56d0207d3c/page/c5B8C?params=${encodeURIComponent(
            JSON.stringify({ "ds0.token": dsccRefreshToken })
          )}`}
          frameBorder="0"
          width="100%"
        />
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default DashboardDetails;
