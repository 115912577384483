
import { Hidden, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';

type ActionProps = {
  children: JSX.Element;
  title: string;
};

const Action = (props: ActionProps) => {
  return props.children;
}

type InfoProps = {
  children: JSX.Element;
  title: string;
}

const Info = (props: InfoProps) => {
  return props.children;
}

type MainProps = {
  children?: JSX.Element | (JSX.Element | boolean)[];
  style?: React.CSSProperties;
  primary?: string;
  secondary?: string;
}

const Main = ({children, primary, secondary, style}: MainProps) => {
  const childArray = children
    ? (Array.isArray(children) ? children : [children]).filter(item => item && typeof item !== 'boolean')
    : [];

  if(primary || secondary) {
    childArray.push((
      <ListItemText
        style={style}
        primary={primary}
        secondary={secondary}
      />
    ));
  }

  return (
    <>
      {childArray}
    </>
  );
}

type Field = {
  icon: JSX.Element;
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
};

const Field = ({
  icon, children, style
}: Field) => {
  children = Array.isArray(children) ? children : [children];

  const actions = children.filter((child) => child.type === Action || child.type === Info);
  const main = children.find((child) => child.type === Main);

  const baseStyle = {display: 'flex', flexFlow: 'row nowrap', alignItems: 'center'};

  return (
    <ListItem style={Object.assign(baseStyle, style)}>
      <Hidden xsDown>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      </Hidden>

      {main}

      <ListItemSecondaryAction style={{display: 'flex', flexFlow: 'row wrap'}}>
        {actions && actions.map((action:JSX.Element) => (
          <Tooltip title={action.props.title}>
            <div style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>{action}</div>
          </Tooltip>
        ))}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

Field.Info = Info;
Field.Action = Action;
Field.Main = Main;
export default Field;