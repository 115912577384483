import { IconButton } from '@material-ui/core';
import { Assignment, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons';
import { AxiosResponse } from "axios";
import Field from 'pages/Account/Field';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TenantApiClient } from 'services/apiClient';

type LookerStudioAPIKey = {
  showHideButtonDisabled?: boolean;
  style?: React.CSSProperties;
  key?: string;
}

const LookerStudioAPIKey = ({
  showHideButtonDisabled, style, key
}: LookerStudioAPIKey) => {
  const [isShowing, setIsShowing] = useState(false);
  const [apiKey, setApiKey] = useState('');

  const getApiKey = () => {
    if(key) {
      setApiKey(key);
    } else {
      TenantApiClient.getTenantApiKeyForLookerStudio().then((response: AxiosResponse<string>) => {
        setApiKey(response.data ?? '');
      });
    }
  }

  useEffect(getApiKey, [isShowing]); 

  return (
    <Field style={style} icon={<VpnKey />}>
      <Field.Main
        primary={isShowing ? apiKey : 'DQOps Cloud Looker Studio Community Connector API Key'}
        secondary={isShowing ? undefined : 'Click the Show icon on the right to view the DQOps Cloud API Key for Looker Studio'}
        style={{overflowWrap: 'anywhere', minHeight: '62px', marginRight: '65px', wordBreak: 'break-all', paddingTop: '0.5rem',
                backgroundColor: isShowing ? 'lightgray' : undefined, border: isShowing ? '1px inset black' : undefined}}
      />

      <Field.Action title='Copy'>
        <CopyToClipboard text={apiKey}>
          <IconButton
            disabled={!isShowing || showHideButtonDisabled}
            onClick={() => console.log('copy')}
          >
            <Assignment />
          </IconButton>
        </CopyToClipboard>
      </Field.Action>

      <Field.Action title={isShowing ? 'Hide' : 'Show'}>
        {isShowing
        ? <IconButton
            disabled={showHideButtonDisabled}
            onClick={() => setIsShowing(false)}
          >
            <VisibilityOff />
          </IconButton>
        : <IconButton
            disabled={showHideButtonDisabled}
            onClick={() => setIsShowing(true)}
          >
            <Visibility />
          </IconButton>
        }
      </Field.Action>
    </Field>
  );
}

export default LookerStudioAPIKey;