import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';

type Collection = {
  children: React.ReactNode;
  spacing: 0 | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  elementWidth?: number;
  pageSize?: number;
  style?: React.CSSProperties;
  className?: string;
};

const Collection = ({children, spacing, elementWidth, pageSize, style, className}: Collection) => {
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridItemRef = useRef<HTMLDivElement>(null);
  const [fill, setFill] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if(elementWidth && pageSize) {
      const newFill = [];
      const size = Array.isArray(children)
        ? pageSize - children.length
        : pageSize - 1;

      const width = gridItemRef.current?.firstElementChild?.getBoundingClientRect().width ?? elementWidth;

      for(var i = 0; i < size; ++i) {
        newFill.push(
          <Grid item xs={3}>
            <div style={{minWidth: width, maxWidth: width}}/>
          </Grid>
        );
      }

      setFill(newFill);
    }
  }, [pageSize, elementWidth]);

  return (
    <Grid
      ref={gridContainerRef}
      container
      spacing={spacing}
      style={style}
      className={className}
    >
      {React.Children.map(children, (child) => (
        <Grid ref={gridItemRef} item>
          {child}
        </Grid>
      ))}
      {fill}
    </Grid>
  );
};

Collection.defaultProps = {
  elementWidth: 240,
};

export default Collection;
