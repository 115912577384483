import React from "react";
import dashboardsData from "../../../data/dashboards.json";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IDashboard } from "../../../types/interfaces";
import { useDashboard } from "../../../contexts/dashboard.context";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  dashboardCard: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: theme.spacing(30),
    marginBottom: theme.spacing(1),
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    marginBottom: theme.spacing(1),
    position: "relative",
    paddingLeft: theme.spacing(3),
  },
  checkmark: {
    position: "absolute",
    top: "50%",
    left: theme.spacing(-1.5),
    transform: "translateY(-50%)",
  },
  title: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  label: {
    fontSize: theme.spacing(1.75),
    marginRight: theme.spacing(1),
  },
  body2: {
    fontSize: theme.spacing(1.75),
  },
  button: {
    fontSize: theme.spacing(1.75),
    textTransform: "unset",
    width: "initial",
    flexGrow: 0,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Dashboards = () => {
  const classes = useStyles();
  const { selectedDashboards, addDashboard, removeDashboard } = useDashboard();

  const isSelected = (dashboard: IDashboard) =>
    !!selectedDashboards.find(
      (item: IDashboard) => item.title === dashboard.title
    );

  return (
    <div>
      <Typography className={classes.heading} variant="h1" component="h1">
        Dashboards
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        {dashboardsData.map((dashboard, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Paper className={classes.dashboardCard}>
              <img className={classes.image} src={dashboard.screenshot} />
              <Box className={classes.header}>
                <Checkbox
                  className={classes.checkmark}
                  color="primary"
                  checked={isSelected(dashboard)}
                />
                <Typography
                  className={classes.title}
                  variant="h2"
                  component="h2"
                >
                  {dashboard.title}
                </Typography>
              </Box>
              <Box className={classes.flexWrapper}>
                <Typography className={classes.label}>Size:</Typography>
                <Typography className={classes.body2}>
                  {dashboard.width}X{dashboard.height}
                </Typography>
              </Box>
              <Box className={classes.footer}>
                <Box className={classes.flexWrapper}>
                  <Typography className={classes.label}>Category:</Typography>
                  <Typography className={classes.body2}>
                    {dashboard.category.split("/")[0]}
                  </Typography>
                </Box>
                {isSelected(dashboard) ? (
                  <Button
                    className={classes.button}
                    variant="text"
                    color="secondary"
                    onClick={() => removeDashboard(dashboard)}
                  >
                    Remove from my dashboards
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    variant="text"
                    color="primary"
                    onClick={() => addDashboard(dashboard)}
                  >
                    Add to my dashboards
                  </Button>
                )}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Dashboards;
