import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles, Theme, StyleRules } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

const styles = (theme: Theme): StyleRules<string> => ({
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
});

class LaunchScreen extends Component {
  static propTypes = {
    // Styling
    classes: PropTypes.object.isRequired,
  };

  render() {
    // Styling
    const { classes } = this.props as any;

    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );
  }
}

export default withStyles(styles)(LaunchScreen);
