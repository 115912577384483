import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Tooltip
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, FirstPage } from "@material-ui/icons";
import useStyles from 'styles';
import { useHistory, useLocation } from "react-router-dom";
import * as querystring from 'query-string';

type Pagination = {
  fetchPageFunction: (pageSize: number, offset: number) => Promise<any>;
  fetchedDataCount: number;
  pageSize: number;
  setPageSize: (arg0: number) => void;
  pageSizeOptions: number[];
  useCreateCard?: boolean;
  update?: boolean;
  offset?: number;
};

const Pagination = ({
  fetchPageFunction,
  fetchedDataCount,
  pageSize,
  pageSizeOptions,
  setPageSize,
  useCreateCard,
  update,
  offset: defaultOffset
}: Pagination) => {
  const [offset, setOffset] = useState<number>(defaultOffset ?? 1);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isStartPage, setIsStartPage] = useState<boolean>(true);

  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const changeOffset = (index: number) => {
    setOffset((prevState) => {
      return prevState + index > 0 && prevState + index < 50
        ? prevState + index
        : prevState
    });
  };

  const changePageSizeHandler = (value: unknown) => {
    setPageSize(value as number);
  };

  const _pageSizeOptions = pageSizeOptions;

  async function fetchData() {
    await fetchPageFunction(pageSize, offset);
    setIsStartPage(offset === 1);
  }

  React.useEffect(() => {
    if(update) {
      fetchData();
    }
  }, [update]); // dummy variable to force refresh

  React.useEffect(() => {
    if(offset !== 1) {
      history.push({
        pathname: location.pathname,
        search: `?${querystring.stringify({
          page: offset
        })}`
      });
    } else {
      // if on page 1, don't push page to url
      history.push({
        pathname: location.pathname
      });
    }
  }, [offset]);

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pageSize, offset, fetchPageFunction]);

  React.useEffect(() => {
    setIsLastPage(fetchedDataCount <= pageSize);
  }, [fetchedDataCount, pageSize]);

  return (
    <Box className={classes.paginationWrapper}>
      <Box p={5}>
        <ButtonGroup variant="text" size="small">
          <Tooltip title='First page'>
            <Button disabled={isStartPage} onClick={() => setOffset(1)}>
              <FirstPage />
            </Button>
          </Tooltip>

          <Tooltip title='Previous'>
            <Button disabled={isStartPage} onClick={() => changeOffset(-1)}>
              <ChevronLeft />
            </Button>
          </Tooltip>

          <Tooltip title='Next'>
            <Button
              disabled={isLastPage}
              onClick={() => (isLastPage ? {} : changeOffset(1))}
            >
              <ChevronRight />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>
      <Box p={5}>
        <Typography variant="body1" color="textSecondary">
          Page: {offset}
        </Typography>
      </Box>
      <Box p={5} className={classes.paginationSizeSelect}>
        <Select
          id="page_size_select"
          onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
            changePageSizeHandler(e.target.value)
          }
          value={useCreateCard ? pageSize + 1 : pageSize}
          disabled={_pageSizeOptions.length <= 1}
          style={{position: 'relative'}}
        >
          {_pageSizeOptions.map((pageSizeOption, index) => (
            <MenuItem value={pageSizeOption} key={"pagination_option_" + index}>
              {_pageSizeOptions[index]}
            </MenuItem>
          ))}
        </Select>
        <InputLabel>Items per page</InputLabel>
      </Box>
    </Box>
  );
};

export default Pagination;
