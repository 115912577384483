import React from 'react';
import { IIcon } from 'types/interfaces';
import Icon from '../index';

import library from './icons/library.png';
import templates from './icons/templates.png';
import replacementImages from './icons/replacement_images.png';
import generateImage from './icons/generate_image.png';
import generatedImages from './icons/generated_images.png';
import dashboard from './icons/dashboard.png';
import home from './icons/home.svg';
import profile from './icons/profile.png';
import user from './icons/user.svg';

interface MenuIcon extends IIcon {
  type: string;
  size?: "sm" | "md" | "lg";
}

const MenuIcon = ({ type, size }: MenuIcon) => {
  const iconTypes = {
    library: library,
    templates: templates,
    replacementImages: replacementImages,
    generateImage: generateImage,
    generatedImages: generatedImages,
    dashboard,
    home,
    profile,
    user,
  };

  return (
    <Icon
      type={type}
      size={size}
      iconTypes={iconTypes}
    />
  );
};

export default MenuIcon;
