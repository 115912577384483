
import React, { useState } from 'react';
import { Collection, Loader, Pagination } from 'components';
import useStyles from 'styles'; 
import { useQuery } from 'hooks';

type PaginationList = {
  isLoading: boolean,
  children: React.ReactNode,
  fetchPageFunction: any,
  fetchedDataCount: number,
  initialPageSize?: number,
  pageSizeOptions?: number[]

  useCreateCard?: boolean;

  style?: any;
  update?: boolean; // dummy variable to force refresh
}

// TODO pagination list doesn't update until refresh
const PaginationList = ({
  isLoading,
  children,
  fetchPageFunction,
  fetchedDataCount,
  initialPageSize,
  pageSizeOptions,
  useCreateCard,
  style,
  update
} : PaginationList) => {
  initialPageSize = initialPageSize ?? PaginationList.defaultProps.initialPageSize;
  pageSizeOptions = pageSizeOptions ?? PaginationList.defaultProps.pageSizeOptions;

  const [offset, setOffset] = useQuery<number>('page', 1, false, value => parseInt(value as string));
  const [pageSize, setPageSize] = useState<number>(useCreateCard ? initialPageSize - 1 : initialPageSize);
  const setAdjustedPageSize = (newPageSize: number) => {
    if(useCreateCard) {
      setPageSize(newPageSize - 1);
    } else {
      setPageSize(newPageSize);
    }
  }

  const collectionStyle = Object.assign({minHeight: '744px'}, style);

  const classes = useStyles();
  return (
    <div className={classes.paginationListWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <Collection spacing={4} className={classes.paginationCollection} style={collectionStyle} pageSize={useCreateCard ? pageSize + 1 : pageSize}>
          {children}
        </Collection>
      )}

      <hr style={{width: '100%'}} />

      <Pagination
        fetchPageFunction={fetchPageFunction}
        fetchedDataCount={fetchedDataCount}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        setPageSize={setAdjustedPageSize}
        useCreateCard={useCreateCard}
        update={update}
        offset={offset}
      />
    </div>
  );
}

PaginationList.defaultProps = {
  initialPageSize: 12,
  pageSizeOptions: [12, 24, 36],
};

export default PaginationList;