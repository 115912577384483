import { Dispatcher } from "flux";
import { ReduceStore } from "flux/utils";
import { ApiKeyRequestActions } from "./ApiKeyRequestActions";
import ApiKeyRequestActionTypes from "./ApiKeyRequestActionTypes";
import { ApiKeyRequestState } from "./ApiKeyRequestState";
import { apiKeyRequestDispatcher } from "./ApiKeyRequestDispatcher";

class ApiKeyRequestStore extends ReduceStore<ApiKeyRequestState, ApiKeyRequestActions> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(dispatcher: Dispatcher<ApiKeyRequestActions>) {
    super(dispatcher);
  }
  getInitialState(): ApiKeyRequestState {
    return new ApiKeyRequestState();
  }

  reduce(state: ApiKeyRequestState, action: ApiKeyRequestActions): ApiKeyRequestState {
    switch (action.type) {
      case ApiKeyRequestActionTypes.API_KEY_REQUESTED:
        if (action.apiKeyRequest) {
          return new ApiKeyRequestState(action.apiKeyRequest);
        }
        return state;
      case ApiKeyRequestActionTypes.API_KEY_PICKED:
        return new ApiKeyRequestState();
        
      default:
        return state;
    }
  }
}
export const apiKeyRequestStore = new ApiKeyRequestStore(apiKeyRequestDispatcher);

