
import React, { useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type RequestInterceptor = (value: AxiosRequestConfig) => AxiosRequestConfig;
export type ResponseInterceptor = (value: AxiosResponse<any>) => AxiosResponse<any>;
export type ErrorInterceptor = (value: any) => any | undefined;

export type Interceptor = RequestInterceptor | ResponseInterceptor | ErrorInterceptor;

type InterceptorObject<T> = {id: string, value: T};

type InterceptorSingleton = {
  request: InterceptorObject<RequestInterceptor>[];
  requestError: InterceptorObject<ErrorInterceptor>[];
  
  response: InterceptorObject<ResponseInterceptor>[];
  responseError: InterceptorObject<ErrorInterceptor>[];
}

const Interceptors: InterceptorSingleton = {
  request: [],
  requestError: [],

  response: [],
  responseError: []
};

axios.interceptors.request.use((res) => {
  return Interceptors.request.reduce((prev, current) => current.value(prev), res);
}, (error) => {
  return Promise.reject(
    Interceptors.requestError.reduce((prev, current) => current.value(prev), error)
  );
})

axios.interceptors.response.use((res) => {
  return Interceptors.response.reduce<AxiosResponse<any>>((prev, current) => current.value(prev), res);
}, (error) => {
  return Promise.reject(
    Interceptors.responseError.reduce((prev, current) => current.value(prev), error)
  );
});

const useInterceptor = (type: 'request' | 'response', id: string, callback: Interceptor, error?: boolean) => {
  useEffect(() => {
    const arrayString = `${type}${error ? 'Error' : ''}`;
    
    // filter them out here, bc a useEffect callback would memoize current interceptor singleton
    Interceptors[arrayString] = Interceptors[arrayString].filter((item: InterceptorObject<any>) => item.id !== id);
    Interceptors[arrayString].push({id: id, value: callback});
  }, [callback]);
}

export default useInterceptor;