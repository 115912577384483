
import React, { MouseEvent, ReactNode, RefObject, forwardRef } from 'react';
import classnames from 'classnames';
import useStyles from 'styles';

type Button = {
	onClick: (event: MouseEvent) => void;
	children: ReactNode;
	className?: string;
	style?: React.CSSProperties;
	white?: boolean;
}

const Button = forwardRef<HTMLButtonElement, Button>(({
	onClick, children, className, style, white
}, ref) => {
	const classes = useStyles();

	return (
		<button onClick={onClick} ref={ref} className={classnames(classes.defaultButton, {[classes.whiteButton]: white}, className)} style={style}>
			{children}
		</button>
	);
});

export default Button;