import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    margin: '5px'
  },
});

function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
