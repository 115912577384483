
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import useStyles from 'styles';

type AlertDialog = {
	prompt: string;
	onAccept: () => void;

	acceptText?: string;
	cancelText?: string;
	style?: React.CSSProperties;

	desc?: string;
	onClose?: () => void;
	forceOpen?: boolean;
}

const AlertDialog = ({
	prompt, onAccept, acceptText, cancelText, desc, onClose, forceOpen, style
}: AlertDialog) => {
	const classes = useStyles();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const handleDialogClose = () => {
		setDialogOpen(false);
		onClose && onClose();
	}

	useEffect(() => {
		if(forceOpen) {
			setDialogOpen(forceOpen);
		}
	}, [forceOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleDialogClose}
			aria-labelledby='confirm-dialog'
			style={style}
		>
			<DialogTitle>{prompt}</DialogTitle>
			{desc &&
				<DialogContent>
					<DialogContentText>{desc}</DialogContentText>
				</DialogContent>
			}
			<DialogActions>
				<Button onClick={() => {
					onAccept();
					handleDialogClose();
				}}>
					{acceptText ? acceptText : 'Delete'}
				</Button>

				<Button onClick={handleDialogClose}>
					{cancelText ? cancelText : 'Cancel'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AlertDialog;