
import React from 'react';
import useStyles, { colors, defaults } from 'styles';
import classnames from 'classnames';

type Header = {
  title: string;
  subtitle?: string;
  style?: React.CSSProperties;
  className?: string;
  close?: () => void;
};

const Header: React.FC<Header> = ({ children, title, subtitle, style, className, close }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.headerWrapper, className)} style={style}>
      <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'flex-end'}}>
        <h1 style={{ margin: 0, color: colors.light, fontFamily: defaults.fonts.primary }}>{title}</h1>
        {subtitle && <p style={{margin: 0, marginLeft: '15px'}}>{subtitle}</p>}
      </div>
      <div className={classes.headerActions}>
        {children}
        {close &&
          <span
            onClick={close}
            className={classes.closePopupIcon}
          >
            &times;
          </span>
        }
      </div>
    </div>
  );
};

export default Header;
export { default as HeaderButton } from './HeaderButton';
export { default as HeaderFileButton } from './HeaderFileButton';
export { default as HeaderIconButton } from './HeaderIconButton';
export { default as HeaderLinkButton } from './HeaderLinkButton';