import React, { Component, ChangeEvent } from 'react';

import PropTypes from 'prop-types';

import { withStyles, Theme, StyleRules } from '@material-ui/core/styles';

import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  Security as SecurityIcon,
} from '@material-ui/icons';

import SwipeableViews from 'react-swipeable-views';

import AccountTab from 'components/AccountTab';
import SecurityTab from 'components/SecurityTab';

const styles = (theme: Theme): StyleRules<string> => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  tabs: {
    display: "initial",
  },
});

const tabs = [
  {
    key: "account",
    icon: <AccountCircleIcon />,
    label: "Account",
  },
  {
    key: "security",
    icon: <SecurityIcon />,
    label: "Security",
  },
];

const initialState = {
  selectedTab: 0,
};

class SettingsDialog extends Component {
  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  static propTypes = {
    // Styling
    classes: PropTypes.object.isRequired,

    // Dialog Properties
    dialogProps: PropTypes.object.isRequired,

    // Custom Properties
    theme: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userData: PropTypes.object,

    // Custom Events
    onDeleteAccountClick: PropTypes.func.isRequired,
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleTabChange = (event: ChangeEvent<{}>, value: any) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleIndexChange = (index: number, indexLatest: number) => {
    this.setState({
      selectedTab: index,
    });
  };

  render() {
    // Styling
    const { classes } = this.props as any;

    // Dialog Properties
    const { dialogProps } = this.props as any;

    // Custom Properties
    const { user, userData /*, theme*/ } = this.props as any;

    // Custom Functions
    const { onDeleteAccountClick } = this.props as any;

    const { selectedTab } = this.state as any;
    return (
      <Dialog {...dialogProps} onExited={this.handleExited}>
        <DialogTitle disableTypography>
          <Typography variant="h6">Settings</Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              onClick={dialogProps.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <Tabs
          classes={{ root: classes.tabs }}
          style={{ overflow: "initial", minHeight: "initial" }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          variant="fullWidth"
          onChange={this.handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
          })}
        </Tabs>

        <SwipeableViews
          index={selectedTab}
          onChangeIndex={this.handleIndexChange}
        >
          <AccountTab
            user={user}
            userData={userData}
            onDeleteAccountClick={onDeleteAccountClick}
          />
          <SecurityTab
            user={user}
            userData={userData}
          />
        </SwipeableViews>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SettingsDialog);
