import React from "react";
import Body from "components/Body";
import { Theme } from "@material-ui/core/styles";

type MainPage = {
  user: any;
  roles: any;
  performingAction: boolean;
  theme: Theme;
  userData: any;
  openDialog: (dialogId: string, callback?: () => void) => void;
  deleteAccount: () => void;
  closeDialog: (dialogId: string, callback?: () => void) => void;
  signOut: () => void;
  aboutDialog: { open: boolean };
  signUpDialog: { open: boolean };
  signInDialog: { open: boolean };
  settingsDialog: { open: boolean };
  deleteAccountDialog: { open: boolean };
  signOutDialog: { open: boolean };
};

const MainPage = ({
  user,
  roles,
  performingAction,
  theme,
  userData,
  openDialog,
  deleteAccount,
  closeDialog,
  signOut,
  aboutDialog,
  signUpDialog,
  signInDialog,
  settingsDialog,
  deleteAccountDialog,
  signOutDialog,
}: MainPage) => {
  return (
    <Body
      user={user}
      roles={roles}
      performingAction={performingAction}
      theme={theme}
      userData={userData}
      openDialog={openDialog}
      deleteAccount={deleteAccount}
      closeDialog={closeDialog}
      signOut={signOut}
      aboutDialog={aboutDialog}
      signUpDialog={signUpDialog}
      signInDialog={signInDialog}
      settingsDialog={settingsDialog}
      deleteAccountDialog={deleteAccountDialog}
      signOutDialog={signOutDialog}
    />
  );
};

export default MainPage;
