import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const Home = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.title}>
        Welcome to your DQOps Cloud account
      </Typography>
      <div className={classes.content}>
        <Typography className={classes.mb3}>
          If you are a new user, follow the instruction below to install and run the DQOps application using pip manager. <br />
          For more information, check our  <a href="https://dqops.com/docs/">documentation</a>.
        </Typography>
        <ol className={classes.ol}>
          <li className={classes.mb2}>
            <div className={classes.flexWrapper}>
              <span>Prerequisites</span>
            </div>
            <ul className={classes.ul}>
              <li>Python version 3.8 or greater</li>
              <li>Ability to install Python packages with pip</li>
            </ul>
          </li>
          <li className={classes.mb2}>
            <div>Create an empty folder where you want to install DQOps.</div>
          </li>
          <li className={classes.mb2}>
            <div>Install DQOps via pip manager by running</div>
            <ul className={classes.ul}>
              <li ><code>python -m pip install --user --upgrade dqops</code> </li>
            </ul>
          </li>
          <li className={classes.mb2}>
            <div>Run dqops to finalize the installation. This process may take some time as the DQOps distribution will be downloaded <br />
             and the Python environment will be initialized in the DQOps home directory.</div>
            <ul className={classes.ul}>
              <li><code>python -m dqops</code> </li>
            </ul>
          </li >
          <li className={classes.mb2}>
            <div>After installation, you will be asked whether to initialize the <a href="https://dqops.com/docs/dqo-concepts/home-folders/dqops-user-home/">DQOps User Home</a> folder which locally stores data <br />
             such as sensor readouts and data quality check results, as well as th data source configuration. Type Y to create the folder. </div>
          </li>
          <li className={classes.mb2}>
            <div>After creating a <a href="https://dqops.com/docs/dqo-concepts/home-folders/dqops-user-home/">DQOps User Home</a> folder,
                 you will be asked whether to log in to the DQOps cloud. Type Y to log in. </div>
          </li>
          <li className={classes.mb2}>
            <div>Open the DQOps web interface in your browser by CTRL-clicking on the link displayed on the command line <br />
             (for example http://localhost:8888) or by copying the link.</div>
          </li>
        </ol>

        <div className={classes.gridWrapper}>
          <div>
            <div className={classes.label}>
              Contact us for more information
            </div>
            <a href="https://dqops.com/contact-us/">
              <Button variant="contained" className={classes.button} style={{width: "140px", height: "50px"}}>
                Contact Us
              </Button>
            </a>
          </div>

          <div>
            <div className={classes.label}>
              Check our progress on GitHub
            </div>
            <a href="https://github.com/dqops/dqo"><img src="images/home/github.png" alt="GitHub Logo" className={classes.w40} /></a>
          </div>

          <div>
            <div className={classes.label}>
              Download our best practices for effective data quality improvement
            </div>
            <a href="https://dqops.com/dqo_ebook_a_step-by-step_guide_to_improve_data_quality-2/"><img src="images/home/ebook.png" alt="DQOps eBook" className={classes.w40} /></a>
          </div>

          <div>
            <div className={classes.label}>
              Read DQOps documentation
            </div>
            <a href="https://dqops.com/docs/">
              <img src="images/home/book.svg" className={classes.docImage} style={{width: "150px", height: "160px"}}/>
            </a>
          </div>

          <div>
            <div className={classes.label}>
              Visit DQOps home page
            </div>
            <a href="https://dqops.com/">
              <img src="images/home/logo.svg" className={classes.logo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: theme.spacing(4),
    color: theme.palette.common.black,
    padding: theme.spacing(2, 4),
    fontStyle: "normal"
  },
  content: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(2, 4)
  },
  mb3: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1.5)
  },
  ol: {
    listStyleType: "decimal",
    paddingLeft: theme.spacing(2)
  },
  ul: {
    listStyleType: "disc",
    paddingLeft: theme.spacing(2.5)
  },
  label: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1.5)
  },
  mb2: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  docImage: {
    width: theme.spacing(20)
  },
  logo: {
    width: theme.spacing(20)
  },
  gridWrapper: {
    marginTop: theme.spacing(6),
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: "2rem"
  },
  w40: {
    width: theme.spacing(20)
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },
  button: {
    background: theme.palette.teal[500],
    color: theme.palette.common.white,
    textTransform: "capitalize"
  }
}));

export default Home;
