import { AppBar, Button, useMediaQuery } from '@material-ui/core';
import { Bar, MainBody, NavBar } from 'components';
import DialogHost from 'components/DialogHost';
import { useLocalStorage } from 'hooks';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from 'styles';

type Body = {
  user: any;
  roles: any;
  performingAction: boolean;
  theme: any;
  userData: any;
  openDialog: (dialogId: string, callback?: () => void) => void;
  deleteAccount: () => void;
  closeDialog: (dialogId: string, callback?: () => void) => void;
  signOut: () => void;
  aboutDialog: { open: boolean };
  signUpDialog: { open: boolean };
  signInDialog: { open: boolean };
  settingsDialog: { open: boolean };
  deleteAccountDialog: { open: boolean };
  signOutDialog: { open: boolean };
};

const Body = ({
  user,
  roles,
  performingAction,
  theme,
  userData,
  openDialog,
  deleteAccount,
  closeDialog,
  signOut,
  aboutDialog,
  signUpDialog,
  signInDialog,
  settingsDialog,
  deleteAccountDialog,
  signOutDialog,
}: Body) => {
  const [menu, setMenuAnchorEl] = useState({});
  const classes = useStyles();
  const history = useHistory();

  const screen = useMediaQuery('@media screen and (max-width:600px)');

  const openMenu = (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>
  ): void => {
    const anchorEl = event.currentTarget;
    setMenuAnchorEl({ anchorEl });
  };
  const closeMenu = (): void => {
    setMenuAnchorEl({ anchorEl: null });
  };

  const [navbarOpen, setNavbarOpen] = useLocalStorage<boolean>('navbar-open', true);

  const navbarSize = navbarOpen ? 220 : 48;
  const paddingAdjust = 0 //16 + 10; // padding adjust for mainbody

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: 'white'}}>
        <Bar
          user={user}
          roles={roles}
          performingAction={performingAction}
          userData={userData}
          openMenu={openMenu}
          closeMenu={closeMenu}
          onSignUpClick={() => openDialog("signUpDialog")}
          onSignInClick={() => openDialog("signInDialog")}
          onSettingsClick={() => history.replace('/account')}
          onSignOutClick={() => openDialog("signOutDialog")}
          onManageAssetLibrary={() => history.replace('/images')}
          menu={menu}
          open={navbarOpen}
          setOpen={setNavbarOpen}
        />
      </AppBar>

      {/* grid numbers pulled from navbar open/closed size  */}
      <div style={{display: 'grid', gridTemplateColumns: '1fr'}}>
        <NavBar open={navbarOpen} />
        <MainBody user={user} userData={userData} onDeleteAccountClick={() => openDialog('deleteAccountDialog')} roles={roles} 
          style={{paddingLeft: `calc(${screen ? paddingAdjust : navbarSize + paddingAdjust}px)`}}
        />
      </div>

      <DialogHost
        performingAction={performingAction}
        theme={theme}
        user={user}
        userData={userData}
        dialogs={{
          aboutDialog: {
            dialogProps: {
              open: aboutDialog.open,
              onClose: () => closeDialog("aboutDialog"),
            },
          },

          signUpDialog: {
            dialogProps: {
              open: signUpDialog.open,

              onClose: (callback: any) => {
                closeDialog("signUpDialog");
                if (callback && typeof callback === "function") {
                  callback();
                }
              },
            },
          },

          signInDialog: {
            dialogProps: {
              open: signInDialog.open,

              onClose: (callback: any) => {
                closeDialog("signInDialog");
                if (callback && typeof callback === "function") {
                  callback();
                }
              },
            },
          },

          settingsDialog: {
            dialogProps: {
              open: settingsDialog.open,

              onClose: () => closeDialog("settingsDialog"),
            },

            props: {
              onDeleteAccountClick: () => openDialog("deleteAccountDialog"),
            },
          },

          deleteAccountDialog: {
            dialogProps: {
              open: deleteAccountDialog.open,

              onClose: () => closeDialog("deleteAccountDialog"),
            },

            props: {
              deleteAccount: deleteAccount,
            },
          },

          signOutDialog: {
            dialogProps: {
              open: signOutDialog.open,
              onClose: () => closeDialog("signOutDialog"),
              action: signOut
            },

            props: {
              title: "Sign out?",
              contentText:
                "While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.",
              dismissiveAction: (
                <Button
                  color="primary"
                  onClick={() => closeDialog("signOutDialog")}
                >
                  Cancel
                </Button>
              ),
              confirmingAction: (
                <Button
                  color="primary"
                  disabled={performingAction}
                  variant="contained"
                  onClick={signOut}
                >
                  Sign Out
                </Button>
              ),
            },
          },
        }}
      />
    </>
  );
};

export default Body;
export { default as Bar } from './Bar';
export { default as MainBody } from './MainBody';

