
import React from 'react';
import useStyles from 'styles';

const Preloader = () => {
  const classes = useStyles();
  return (
    <div className={classes.preloaderWrapper}>
      <div className={classes.preloader}></div>
    </div>
  );
};

export default Preloader;
