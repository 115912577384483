import React, { useEffect, useState } from "react";

import { IDashboard } from "../types/interfaces";

const DashboardContext = React.createContext({} as any);

function DashboardProvider(props: any) {
  const [selectedDashboards, setSelectedDashboards] = useState<IDashboard[]>(
    []
  );

  useEffect(() => {
    const storageData: any = localStorage.getItem("dqo_cloud_dashboards");
    if (storageData) {
      try {
        const dashboards = JSON.parse(storageData);
        setSelectedDashboards(dashboards);
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "dqo_cloud_dashboards",
      JSON.stringify(selectedDashboards)
    );
  }, [selectedDashboards]);

  const addDashboard = (dashboard: IDashboard) => {
    setSelectedDashboards([...selectedDashboards, dashboard]);
  };

  const removeDashboard = (dashboard: IDashboard) => {
    setSelectedDashboards(
      selectedDashboards.filter((item) => dashboard.title !== item.title)
    );
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedDashboards,
        addDashboard,
        removeDashboard,
      }}
      {...props}
    />
  );
}

function useDashboard() {
  const context = React.useContext(DashboardContext);

  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
}

export { DashboardProvider, useDashboard };
