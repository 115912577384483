import { Dispatcher } from "flux";
import { ReduceStore } from "flux/utils";
import { DqoLoginActions } from "./DqoLoginActions";
import DqoLoginActionTypes from "./DqoLoginActionTypes";
import { DqoLoginState } from "./DqoLoginState";
import { dqoLoginDispatcher } from "./DqoLoginDispatcher";

class DqoLoginStore extends ReduceStore<DqoLoginState, DqoLoginActions> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(dispatcher: Dispatcher<DqoLoginActions>) {
    super(dispatcher);
  }
  getInitialState(): DqoLoginState {
    return new DqoLoginState();
  }

  reduce(state: DqoLoginState, action: DqoLoginActions): DqoLoginState {
    switch (action.type) {
      case DqoLoginActionTypes.DQO_LOGIN_REQUESTED:
        if (action.tgt && action.returnUrl) {
          return new DqoLoginState(action.tgt, action.returnUrl, action.accountName ?? '');
        }
        return state;

      case DqoLoginActionTypes.DQO_LOGIN_REDIRECTING:
        return new DqoLoginState();
      
      case DqoLoginActionTypes.DQO_LOGIN_WRONG_TENANT:
        return new DqoLoginState();
          
      default:
        return state;
    }
  }
}
export const dqoLoginStore = new DqoLoginStore(dqoLoginDispatcher);

